import React, { useContext, useEffect } from "react";
import { DashboardContext } from "../../context/DashboardContext";
import CountCard from "./CountCard";
import routes from "../../routes";
import * as Icon from "react-feather";

const CountCardContainer = () => {
  const { summary, fetch_dashboard_summary } = useContext(DashboardContext);

  console.log("summary from context", summary)
 

  const data = [
    {
      link: routes.policies,
      label: "Policies",
      summary: summary.clients,
      icon: <Icon.Users />,
      style: "card-icon-warning",
    },
    {
      link: routes.quotationRequests,
      label: "Requests",
      summary: summary.requests,
      icon: <Icon.Globe />,
      style: "card-icon-primary",
    },
    {
      link: routes.quotations,
      label: "Quotations",
      summary: summary.quotations,
      icon: <Icon.FileText />,
      style: "card-icon-success",
    },
    {
      link: routes.renewals,
      label: "Renewals",
      summary: summary.renewals,
      icon: <Icon.RefreshCcw />,
      style: "card-icon-danger",
    },
  ];

  useEffect(() => {
    try {
      fetch_dashboard_summary();
    } catch (error) {
      console.log(error.response);
    }
  }, []);

  return (
    <div>
      <CountCard summary={data} />
    </div>
  );
};

export default CountCardContainer;
