import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import CustomTable from "components/widgets/table";
import routes from "routes";

export default function MyPolicies({ data, handle_view_portfolio }) {
  const tableData = {
    icon: "",
    title: "",
    table_fields: [
      { name: "", align: "c" },
      { name: "Client Name", align: "c" },
      { name: "Product Status", align: "c" },
      { name: "Cover Start Date", align: "c" },
      { name: "Cover End Date", align: "c" },
      { name: "Sum Assured", align: "c" },
      { name: "Action", align: "c" },
    ],
    search_fields: ["question_code", "answer"],
    row_data: [
      { type: "image", field: "scheme_logo", class: "center" },
      { type: "text", field: "names", class: "center" },
      { type: "status", field: "product_status", class: "center" },
      { type: "date", field: "cover_start_date", class: "center" },
      { type: "date", field: "cover_end_date", class: "center" },
      { type: "currency", field: "premium_amount", class: "center" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            field: "client_product_id",
            method: handle_view_portfolio,
          },
        ],
      },
    ],
  };
  console.log("policies", tableData)

  return (
    <>
      <div className="pageheader">
        <div className="breadcrumb pd-0 mg-0 pd-b-20">
          <Link className="breadcrumb-item active" to={routes.dashboard}>
            <i className="icon ion-ios-home-outline " /> Home
          </Link>
          <Link className="breadcrumb-item ">Policies</Link>
        </div>
        <h5>My Managed Policies</h5>
      </div>
      <CustomTable
        table_fields={tableData.table_fields}
        row_data={tableData.row_data}
        table_data={data}
        title={tableData.title}
      />
    </>
  );
}

MyPolicies.propTypes = {
  handle_view_portfolio: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};
