import React, { useContext } from 'react';
import { TimePicker, Tabs, DatePicker } from 'antd';
import * as Icon from 'react-feather';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';

import { AlertsContext } from 'context/AlertsContext';
import { DashboardContext } from 'context/DashboardContext';
import { AuthContext } from 'context/AuthContext';
import MultiSelect from 'react-multi-select-component';
import Alert from 'components/alerts/WarningAlert';

//  Validation
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

export default function ({ setEvents, onTimeChange, handleChange, handleUnderwritersListChange, currentEvents }) {
	
	// Access Context

	const { appointment, myUnderwriters, refreshAppointments, setRefreshAppointments } = useContext(DashboardContext);
	const { showAlert } = useContext(AlertsContext);
	const { alert } = useContext(AuthContext);

	// Destructure

	const { variant, message } = alert;
	const { TabPane } = Tabs;


	// const options = myUnderwriters.data.map((option) => {
	// 	return { label: option.name, value: option.code };
	// });
	return (
		<div>
			<Formik
				enableReinitialize
				initialValues={appointment}
				validationSchema={Yup.object().shape({
					title: Yup.string().required('Title of appointment is required'),
					name: Yup.string().required('Client Name is required'),
					phone: Yup.string().required('Phone Number is required'),
					underwriter: Yup.string(), //.required('Please select an underwriter'),
					time: Yup.string().required('Time is required'),
					location: Yup.string().required('Enter a location '),
					description: Yup.string().required('A description is required'),
					email: Yup.string().email('Please enter a valid email'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					setEvents(values);
					// setRefreshAppointments(true);
					setRefreshAppointments((prev) => {
						console.log("Previous refreshAppointments:", prev);
						return true;
					});
					console.log("setting refreshment", refreshAppointments)
					setSubmitting(false);
				
				  }}
			>
				{({ errors, touched }) => (
					<Tabs defaultActiveKey="1">
						<TabPane tab="Appointment" key="1">
							<Form className="login-form">
								<div className="form-row">
									{showAlert && <Alert message={message} variant={variant} />}
									<div className="col-md-12">
										<div className="form-group ">
											<input
												name="title"
												type="text"
												placeholder="Enter a title"
												value={appointment.title}
												onChange={handleChange}
												className={
													'form-control border-bottom' +
													(errors.title && touched.title ? ' is-invalid' : '')
												}
											/>

											<ErrorMessage name="title" component="div" className="invalid-feedback" />
										</div>
									</div>
									<div className="col-md-12 form-group">
										<div className="row">
											<div className="col-md-1">
												<Icon.User size={20} />
											</div>
											<div className="col-md-11">
												<input
													placeholder="Client Name"
													name="name"
													type="text"
													value={appointment.name}
													onChange={handleChange}
													className={
														'form-control border-bottom' +
														(errors.name && touched.name ? ' is-invalid' : '')
													}
												/>

												<ErrorMessage
													name="name"
													component="div"
													className="invalid-feedback"
												/>
											</div>
										</div>
									</div>
									<div className="col-md-12 form-group">
										<div className="row">
											<div className="col-md-1">
												<Icon.Phone size={20} />
											</div>
											<div className="col-md-11">
												<input
													name="phone"
													placeholder="Client's Contact"
													type="text"
													value={appointment.phone}
													onChange={handleChange}
													className={
														'form-control border' +
														(errors.phone && touched.phone ? ' is-invalid' : '')
													}
												/>
												<ErrorMessage
													name="phone"
													component="div"
													className="invalid-feedback"
												/>
											</div>
										</div>
									</div>
									<div className="col-md-12 form-group">
										<div className="row">
											<div className="col-md-1">
												<Icon.Mail size={20} />
											</div>
											<div className="col-md-11">
												<input
													name="email"
													placeholder="Client's Email"
													type="text"
													value={appointment.email}
													onChange={handleChange}
													className={
														'form-control border' +
														(errors.email && touched.email ? ' is-invalid' : '')
													}
												/>
												<ErrorMessage
													name="email"
													component="div"
													className="invalid-feedback"
												/>
											</div>
										</div>
									</div>
									{/* <div className="col-md-12 form-group">
										<div className="row">
											<div className="col-md-1">
												<Icon.Briefcase size={20} />
											</div>
											<div className="col-md-11">
												<MultiSelect
													options={options}
													value={appointment.underwriter}
													onChange={handleUnderwritersListChange}
													labelledBy={'Select'}
													className={
														errors.underwriter && touched.underwriter ? ' is-invalid' : ''
													}
												/>
												<ErrorMessage
													name="underwriter"
													component="div"
													className="invalid-feedback"
												/>
											</div>
										</div>
									</div> */}
									<div className="col-md-12 form-group">
										<div className="row">
											<div className="col-md-1">
												<Icon.Clock size={20} />
											</div>

											<div className="col-md-11">
												<TimePicker
													name="time"
													onChange={onTimeChange}
													className={
														'border' + (errors.phone && touched.phone ? ' is-invalid' : '')
													}
												/>

												<ErrorMessage
													name="time"
													component="div"
													className="invalid-feedback"
												/>
											</div>
										</div>
									</div>

									<div className="col-md-12 form-group">
										<div className="row">
											<div className="col-md-1">
												<Icon.Map size={20} />
											</div>
											<div className="col-md-11">
												<input
													name="location"
													type="text"
													placeholder="Appointment Location"
													value={appointment.location}
													onChange={handleChange}
													className={
														'form-control border' +
														(errors.location && touched.location ? ' is-invalid' : '')
													}
												/>
												<ErrorMessage
													name="location"
													component="div"
													className="invalid-feedback"
												/>
											</div>
										</div>
									</div>
									<div className="col-md-12 form-group">
										<div className="row">
											<div className="col-md-1">
												<Icon.FileText size={20} />
											</div>
											<div className="col-md-11">
												<textarea
													rows="4"
													type="text"
													name="description"
													placeholder="Brief description of appointment"
													className={
														'form-control border' +
														(errors.description && touched.description ? ' is-invalid' : '')
													}
													value={appointment.description}
													onChange={handleChange}
												/>
												<ErrorMessage
													name="description"
													component="div"
													className="invalid-feedback"
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="d-flex justify-content-center">
									<button
										color="primary"
										variant="contained"
										type="submit"
										className="btn btn-outline-success px-5 my-4 "
									>
										{appointment.loading && (
											<i className="fa fa-circle-notch fa-spin" style={{ marginRight: '5px' }} />
										)}
										{appointment.loading && <span className="text-capitalize">Please wait</span>}
										{!appointment.loading && (
											<span className="text-capitalize"> Request Appointment</span>
										)}
									</button>
								</div>
							</Form>
						</TabPane>
						<TabPane tab="Set Reminder" key="2">
							<div className="form-row">
								<div className="col-md-12 form-group">
									<div className="row">
										<div className="col-md-1">
											<Icon.Clock size={20} />
										</div>

										<div className="col-md-5">
											<TimePicker name="time" use12Hours format="HH:mm" />
										</div>
										<div className="col-md-6">
											<DatePicker />
										</div>
									</div>
								</div>
							</div>
						</TabPane>
					</Tabs>
				)}
			</Formik>
		</div>
	);
}
