import React, { useContext, useState,useEffect, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";

import * as Icon from "react-feather";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import { ImHourGlass, ImCreditCard } from "react-icons/im";

import routes from "routes";
import styles from "./Sidebar.module.css";
import "../../assets/css/dashboard.css";
import { DashboardContext } from "context/DashboardContext";
import Shepherd from "shepherd.js";
import { fetch_appointments, get_quotation_requests } from "services/apiSrv";


export default function Sidebar_Inner() {
  const { SubMenu } = Menu;
  const { isToggled, refreshAppointments, setRefreshAppointments } = useContext(DashboardContext);
  const tourRef = useRef(null);

  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const queryClient = useQueryClient();

  const _appointments = async () => {
		let resp = await fetch_appointments();
		//console.log('1ST THE APPOINTMENTS', resp.intermediary_appointments);
		//setCurrentEvents(resp.intermediary_appointments);

		return resp.intermediary_appointments;
	};
  const [unreadAppointments, setUnreadAppointments] = useState([]);
  const [unreadQuotationRequests, setUnreadQuotationRequests] = useState([]);

  const { isLoading, isError, isSuccess, data: appointments, refetch } = useQuery('appointments', _appointments);

  
  useEffect(() => {
    if (refreshAppointments) {
      refetch().then(() => setRefreshAppointments(false));  
    }
  }, [refreshAppointments]);
  
  useEffect(() => {
    if (appointments?.length) {
      setUnreadAppointments(appointments.filter((appointment) => appointment.isNew));
    }
  }, [appointments]);


  async function fetch_quotation_requests() {
    let resp = await get_quotation_requests();
    return resp.quotation_requests;
  }

  const {  data: quotationRequests } = useQuery(
    "quotationRequests",
    fetch_quotation_requests
  );
 
  useEffect(() => {
    if (appointments?.length) {
      setUnreadQuotationRequests(quotationRequests?.filter((request) => request?.isNew));
    }
  }, [quotationRequests]);


  useEffect(() => {
    const firstLogin = localStorage.getItem("firstLogin");

    if (!firstLogin) {
      setIsFirstLogin(true);
      localStorage.setItem("firstLogin", "true");
    }

  }, []);

  useEffect(() => {    
    if (!isFirstLogin || tourRef.current) return;

    const tour = new Shepherd.Tour({
      useModalOverlay: true, // Enables background overlay
      defaultStepOptions: {
        classes: 'shadow-sm',
        scrollTo: false,
        cancelIcon: {
          enabled: true
        },
      }
    });

    tourRef.current = tour;

    // Centered Modal Step (First Step)
    tour.addStep({
      title: '<h6 class="fs-4 fw-bold  text-primary">Welcome to the Tour!</h6>',
      text:`<p class="fs-6 small">Would you like to start the tour or cancel?`,
      modal: true, // This ensures it's centered
      classes: 'test',
      buttons: [
        {
          text: 'Start Tour',
          action: tour.next, // Moves to the next step
          classes: 'btn btn-primary btn-sm  text-white px-2 rounded-sm shadow-md'
        }
      ]
    });

    tour.addStep({
      title: '<h6 class="fs-4 fw-bold text-primary">Managed Policies</h6>',
      text: `<p class="fs-6 small">These are the policies the intermediary is managing for clients.</p>`,
      attachTo: { element: '.my-policies', on: 'right' },
      buttons: [
        {
          text: 'Next',
          action: tour.next,
          classes: 'btn btn-primary btn-sm  text-white px-2 rounded-sm shadow-md'
        }
      ]
    });

  
    tour.addStep({
      title: '<h6 class="fs-4 fw-bold text-primary">My Underwriters</h6>',
      text: `<p class="fs-6 small">View underwriters that an intermediary can work with.</p>`,
      attachTo: { element: '.my-underwritter', on: 'right' },
      buttons: [
        {
          text: 'Next',
          action: tour.next,
          classes: 'btn btn-primary btn-sm  text-white px-2 rounded-sm shadow-md'
        }
      ]
    });

    tour.addStep({
      title: '<h6 class="fs-4 fw-bold text-primary">My Specializations</h6>',
      text: `<p class="fs-6 small">Classes of insurance that an intermediary sells.</p>`,
      attachTo: { element: '.my-specializations', on: 'right' },
      buttons: [
        {
          text: 'Next',
          action: tour.next,
          classes: 'btn btn-primary btn-sm  text-white px-2 rounded-sm shadow-md'
        }
      ]
    });


    tour.addStep({
      title: '<h6 class="fs-4 fw-bold text-primary">Appointments</h6>',
      text: `<p class="fs-6 small">Allows an intermediary to set up appointments with clients.</p>`,
      attachTo: { element: '.my-appointments', on: 'right' },
      buttons: [
        {
          text: 'Next',
          action: tour.next,
          classes: 'btn btn-primary btn-sm  text-white px-2 rounded-sm shadow-md'
        }
      ]
    });


    tour.addStep({
      title: '<h6 class="fs-4 fw-bold text-primary">Open Leads</h6>',
      text: `<p class="fs-6 small">This is an important feature.</p>`,
      attachTo: { element: '.my-openleads', on: 'right' },
      buttons: [
        {
          text: 'Next',
          action: tour.next,
          classes: 'btn btn-primary btn-sm  text-white px-2 rounded-sm shadow-md'
        }
      ]
    });

    tour.addStep({
      title: '<h6 class="fs-4 fw-bold text-primary">Quotations</h6>',
      text: `<p class="fs-6 small">Allow sending of quotation requests to clients.</p>`,
      attachTo: { element: '.my-quotations', on: 'right' },
      buttons: [
        {
          text: 'Next',
          action: tour.next,
          classes: 'btn btn-primary btn-sm  text-white px-2 rounded-sm shadow-md'
        }
      ]
    });

    
    tour.start(); 

    return () => {
      tour.cancel(); // Cleanup tour on unmount
      tourRef.current = null; // Reset tour reference
    };

  }, [isFirstLogin]);



  return (
    <div className="">
      <div className={styles.logoWrapper}>
        {!isToggled && <div className={styles.logo} />}
      </div>
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={[]}
        class={styles.menu}
      >

        <Menu.Item key="1" icon={<Icon.Home size={19} />} >
          <NavLink to={routes.dashboard}>Dashboard</NavLink>
        </Menu.Item>
        <Menu.Item key="2" icon={<Icon.Users size={19} />} className="my-policies">
          <NavLink to={routes.policies}>Managed Policies </NavLink>
        </Menu.Item>
        <Menu.Item key="3" icon={<Icon.Briefcase size={19} />}>
          <NavLink to={routes.underwriters} class="my-underwritter">My Underwriters</NavLink>
        </Menu.Item>
        <Menu.Item key="4" icon={<Icon.Award size={19} />} className="my-specializations">
          <NavLink to={routes.profession}>My Specializations</NavLink>
        </Menu.Item>
        <Menu.Item key="5" icon={<Icon.Calendar size={19} />} className="my-appointments bg-red-400">
        <NavLink to={routes.appointments} className=" ">
          Appointments
          <span className="text-white text-lg start-100 translate-middle badge rounded-pill fs-3 fw-bold ml-2 bg-danger">
            { unreadAppointments.length }
          </span>
        </NavLink>
        </Menu.Item>
        <Menu.Item key="6" icon={<Icon.ThumbsUp size={19} />}>
          <NavLink to={routes.reviews}>Reviews</NavLink>
        </Menu.Item>
        <Menu.Item key="7" icon={<Icon.Globe size={19} />} className="my-domains">
          <NavLink to={routes.domains}>My Domains</NavLink>
        </Menu.Item>
        <Menu.Item key="8" icon={<Icon.Users size={19} />} className="my-openleads">
          <NavLink to={routes.openLeads}>Open Leads
          <span className="text-white text-lg start-100 translate-middle badge rounded-pill fs-3 fw-bold ml-2 bg-danger">
            5
          </span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="35" icon={<Icon.Users size={19} />}>
          <NavLink to={routes.schemes}>Scheme Management</NavLink>
        </Menu.Item>

        <SubMenu
          key="accountmgt"
          icon={<ImCreditCard size={19} />}
          title="Account Management"
        >
          <Menu.Item key="9">
            <NavLink to={routes.wallet}>Wallet</NavLink>
          </Menu.Item>
          <Menu.Item key="10">
            <NavLink to={routes.tax}>Entity Tax</NavLink>
          </Menu.Item>
          <Menu.Item key="11">
            <NavLink to={routes.fees}>Entity Fees</NavLink>
          </Menu.Item>

          <SubMenu
            key="settlement"
            icon={<ImCreditCard size={19} />}
            title="Settlement"
          >
            <Menu.Item key="12">
              <NavLink to={routes.settlement}> Settlements</NavLink>
            </Menu.Item>
            <Menu.Item key="13">
              <NavLink to={routes.channels}> Settlement Channel</NavLink>
            </Menu.Item>
            <Menu.Item key="14">
              <NavLink to={routes.settlementConfig}>Settlement Config</NavLink>
            </Menu.Item>
          </SubMenu>
        </SubMenu>
        <SubMenu
          key="usermgt"
          icon={<Icon.UserPlus size={19} />}
          title="User Management"
        >
          <Menu.Item key="15">
            <NavLink to={routes.usmgDepartments}> Departments</NavLink>
          </Menu.Item>
          <Menu.Item key="16">
            <NavLink to={routes.usmgRoles}> Roles</NavLink>
          </Menu.Item>
          <Menu.Item key="17">
            <NavLink to={routes.usmgUsers}>Users</NavLink>
          </Menu.Item>
          <Menu.Item key="18">
            <NavLink to={routes.usmgUserGroup}>User Group</NavLink>
          </Menu.Item>
          <Menu.Item key="19">
            <NavLink to={routes.usmgChatGroups}>Chat Group</NavLink>
          </Menu.Item>
          <Menu.Item key="20">
            <NavLink to={routes.usmgSecurity}> Security Context</NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="marketing"
          icon={<Icon.UserPlus size={19} />}
          title="Marketing"
        >
          <Menu.Item key="21">
            <NavLink to={routes.rm}> Relationship Manager</NavLink>
          </Menu.Item>
          <Menu.Item key="22">
            <NavLink to={routes.salesrep}> Sales Representative</NavLink>
          </Menu.Item>
          <Menu.Item key="23">
            <NavLink to={routes.leads}> Leads</NavLink>
          </Menu.Item>
          <Menu.Item key="24">
            <NavLink to={routes.leads}> Promotions</NavLink>
          </Menu.Item>
          <Menu.Item key="25">
            <NavLink to={routes.leads}> Advertisements</NavLink>
          </Menu.Item>
          <Menu.Item key="26">
            <NavLink to={routes.incentives}>Incentives</NavLink>
          </Menu.Item>
        </SubMenu>
        {/* <Menu.Item key="27" icon={<Icon.ThumbsUp size={19} />}>
          <NavLink to={routes.quotations1}>Quotations</NavLink>
        </Menu.Item> */}
        {/* <Menu.Item key="28" icon={<Icon.ThumbsUp size={19} />}>
					<NavLink to={routes.quotationRequests1}>Quotation Requests</NavLink>
				</Menu.Item>  */}
          {/* <SubMenu
          key="quotaion management"
          icon={<Icon.ThumbsUp size={19} />}
          title="Quotation Management"
          className="my-quotations"
        >
       <Menu.Item key="29">
          <NavLink to={routes.quotations}>Quotations</NavLink>
        </Menu.Item> 
        <Menu.Item key="30">
          <NavLink to={routes.quotationRequests}>Quotation Requests </NavLink>
        </Menu.Item>
        </SubMenu>
  */}

      <SubMenu
        key="quotation-management"
        icon={null} // Set to null to manually handle the icon inside the title
        title={
          <span className="d-flex align-items-center">
            <Icon.ThumbsUp size={19} className="me-2" />
            <span className="pl-2">Quotation Management</span>
            <span className="text-white text-lg translate-middle badge rounded-pill fs-6 fw-bold  bg-danger">
              {unreadQuotationRequests.length}
            </span>
          </span>
        }
        className="my-quotations"
      >
        <Menu.Item key="29">
          <NavLink to={routes.quotations}>Quotations</NavLink>
        </Menu.Item>
        <Menu.Item key="30">
          <NavLink to={routes.quotationRequests}>Quotation Requests</NavLink>
        </Menu.Item>
      </SubMenu>


        <SubMenu
          key="claim management"
          icon={<Icon.Shield size={19} />}
          title="Claim Management"
        >
          <Menu.Item key="31" >
            <NavLink to={routes.claims}>Claims</NavLink>
          </Menu.Item>
          <Menu.Item key="32" >
            <NavLink to={routes.claimsPending}>Claims Pending Approval</NavLink>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="33" >
          <NavLink to={routes.chat}>Chats</NavLink>
        </Menu.Item>
        <Menu.Item key="34" icon={<Icon.Info size={19} />}>
          <NavLink to="">Terms and Conditions</NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
}
