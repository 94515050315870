export default {
  // Root

  home: "/",
  signup: "/signup",
  login: "/login",
  forgotPassword: "/forgot-password",
  verifyCode: "/verify-code",
  resetPassword: "/reset-password",
  credentials: "/credentials",
  verify: "/verify",
  kyc: "/kyc",
  reset: "/reset",
  resetPassword: "/reset-password",
  reset2: "/reset2",

  // Nested Dashboard Routes

  dashboard: "/dashboard",
  underwriters: "/dashboard/underwriters",

  // Quotations (lATEST)
  quotations: "/dashboard/quotations",
  viewQuotation: "/dashboard/quotations/view/:id",
  assetDetails: "/dashboard/quotations/asset-details/:id",

  // Quotations
  quotations1: "/dashboard/quotations1",
  viewQuotation1: "/dashboard/quotations1/view/:id",
  assetDetails1: "/dashboard/quotations1/asset-details/:id",

  // claims
  claims: "/dashboard/claims",
  viewClaim: "/dashboard/claims/view/:id",
  claimsPending: "/dashboard/claims-pending-approval",
  viewClaimsPending: "/dashboard/claims-pending-approval/view/:id",

  // renewals
  renewals: "/dashboard/renewals",
  viewRenewal: "/dashboard/renewals/view",

  // Quotation Request (Latest)
  quotationRequests: "/dashboard/quotation-requests",
  viewQuotationRequest: "/dashboard/quotation-requests/view/quo-req/:id",

  // Quotation Request
  quotationRequests1: "/dashboard/quotation-requests1",
  viewQuotationRequest1: "/dashboard/quotation-requests1/view/:id",

  // Request
  requests: "/dashboard/requests",

  // policies
  policies: "/dashboard/policies",
  viewPolicy: "/dashboard/policies/view/:id",

  // Reports
  reportConfig: "/dashboard/reports/config",
  viewReportConfig: "/dashboard/reports/config/view-report",
  viewReportGroup: "/dashboard/reports/config/view-group",
  viewUsers: "/dashboard/reports/config/view-users",

  // UserManagement
  usmgRoles: "/dashboard/usmg/roles",
  usmgRoleView: "/dashboard/usmg/roles/view/:id",
  usmgDepartments: "/dashboard/usmg/departments",
  usmgViewDepartment: "/dashboard/usmg/departments/view/:id",
  usmgUsers: "/dashboard/usmg/users",
  usmgViewUser: "/dashboard/usmg/users/view/:id",
  usmgUserGroup: "/dashboard/usmg/usergroup",
  usmgViewUserGroup: "/dashboard/usmg/usergroup/view/:id",
  usmgChat: "/dashboard/usmg/chat",
  usmgViewChat: "/dashboard/usmg/chat/view",
  usmgViewSecurity: "/dashboard/usmg/security/view/:id",
  usmgSecurity: "/dashboard/usmg/security",
  usmgChatGroups: "/dashboard/usmg/chatgroups",

  //wallet
  wallet: "/dashboard/account-management/wallet",

  // commissions
  commissions: "/dashboard/commissions",

  // profession
  profession: "/dashboard/profession",

  // profile
  profile: "/dashboard/profile",

  // appointments
  appointments: "/dashboard/appointments",

  // Tnc
  tnc: "/dashboard/tnc",

  tenders: "/dashboard/tenders",

  // Settlement
  settlement: "/dashboard/settlement",
  channels: "/dashboard/channels",
  settlementConfig: "/dashboard/settlement/config",
  configDetails: "/dashboard/settlement/config/:id",
  settlementDetails: "/dashboard/settlement/:id",
  channelDetails: "/dashboard/settlement/channel/:id",

  // Reviews
  reviews: "/dashboard/reviews",

  // Chat
  chat: "/dashboard/chat",
  chatConversation: "/dashboard/chat/:id",

  // tax
  tax: "/dashboard/account-management/tax",
  taxDetails: "/dashboard/account-management/tax/details/:id",

  // Relationship Manager
  rm: "/dashboard/rm",
  rmDetails: "/dashboard/rm/:id",

  salesrep: "/dashboard/salesrep",
  viewSalesRep: "/dashboard/salesrep/:id",

  // leads
  openLeads: "/dashboard/open-leads",
  leads: "/dashboard/leads",
  viewLeads: "/dashboard/leads/ld/:id",

  // Fees
  fees: "/dashboard/fees",
  feeDetails: "/dashboard/account-management/fees/details/:id",

  // domains
  domains: "/dashboard/domains",

  // Incentives
  incentives: "/dashboard/incentives",
  incentiveDetails: "/dashboard/incentives/:id",

  // Schemes
  schemes: "/dashboard/schemes",
  schemeDetails: "/dashboard/schemes/:schemeId",
  groupSchemeDetails: "/dashboard/schemes/:schemeId/sch-grp/:groupId",
  underwriterRates:
    "/dashboard/schemes/:schemeId/underwriterRate/sch-grp/:groupRef",
  groupSchemeManagement: "/dashboard/schemes/:schemeId/manage-scheme",
};
