import React from "react";

import PropTypes from "prop-types";

import useForm from "shared/customHooks/useForm";

const AutoForm = ({
  submitFn,
  formFn,
  options = [],
  labelStr,
  valueStr,
  hasOptions = false,
  extralLabel = ""
}) => {
  // Mapping select options
  const _options = options.map((item) => {
    return {
      label: `${item[labelStr]}-${item[extralLabel]}`,
      value: item[valueStr],
    };
  });
  let config = formFn();

  const { renderFormInputs, isFormValid, form } = useForm(
    formFn(hasOptions ? _options : "")
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let validity = isFormValid();
    if (validity === true) {
      submitFn.mutate(form);
    } else {
    }
  };

  // const { messages, spinner } = form.config;

  return (
    <form onSubmit={handleSubmit}>
      {renderFormInputs()}
      <div className="form-bottom">
        <button
          className={`btn btn-${
            config.config.buttonVariant || "primary"
          } btn-block`}
          type="submit"
          disabled={!isFormValid() || submitFn.isLoading}
        >
          {submitFn.isLoading ? (
            <span>
              <i
                className="fa fa-circle-notch fa-spin"
                style={{ marginRight: "5px" }}
              />
              Please wait...
            </span>
          ) : (
            config.config.buttonText || "Button Label"
          )}
        </button>
      </div>
    </form>
  );
};

export default AutoForm;

AutoForm.propTypes = {
  submitFn: PropTypes.object.isRequired,
  formFn: PropTypes.func.isRequired,
  hasOptions: PropTypes.bool.isRequired,
  options: PropTypes.array,
  labelStr: PropTypes.string,
  valueStr: PropTypes.string,
};
