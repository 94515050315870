import React, { useContext, useState, useCallback, useRef } from "react";

import { Select, Form, Rate, Radio, Input } from "antd";
import { useQuery, useMutation } from "react-query";

import { DashboardContext } from "context/DashboardContext";
import { ModalContext } from "context/ModalContext";
import { KycContext } from "context/KycContext";
import {
  add_recommendations,
  list_risks,
  list_special_codes,
} from "services/apiSrv";
import Loader from "components/widgets/loader/ComponentLoader";
import { show_toast } from "utils/helpers";
import { useQueryClient } from "react-query";
import { debounce } from "lodash";


const ProductRecommendation = ({
  systemRef,
  refetchRecommendations,
  quotationRequestRef,
}) => {
  const { recommendation, setRecommendation } = useContext(DashboardContext);
  const { modalClose } = useContext(ModalContext);
  const { setCurrent } = useContext(KycContext);
  //console.log("RECOMMENDA", recommendation);
  const { Option } = Select;
  const { TextArea } = Input;

  const queryClient = useQueryClient();
  const isSubmittingRef = useRef(false); // Tracks if a submission is in progress

  const postRecommendationForm = useMutation(add_recommendations, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("recommendations");
      refetchRecommendations();
      modalClose();
      setCurrent(0);
      show_toast("Recommendation added successfully", "success");
      isSubmittingRef.current = false;
    },
    onError: async (error) => {
      modalClose();
      isSubmittingRef.current = false; 
    },
  }, [recommendation, systemRef]);

  console.log(" ==== ON FINISH POST ==== ",recommendation)
  const onFinish = useCallback(debounce(async (values) => {
    let data = {
      product_id: recommendation.product_id,
      quotation_req_ref: `quo-req/${systemRef}`,
      recommendation_level: values.recommendation_level,
      recommendation_remarks: values.recommendation_remarks,
      special_code: values.special_code,
      risk: values.risk,
    };

    postRecommendationForm.mutate(data);
  }, 500),
  [recommendation, systemRef]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  async function fetch_risks() {
    let resp = await list_risks(systemRef);
    console.log("risk det", resp);
    return resp.risks;
  }

  async function fetch_codes() {
    let resp = await list_special_codes();
    console.log("special codes", resp);
    return resp.intermediary_tokens;
  }

  const onBinderOptionChange = (evt) => {
    const { value } = evt.target;
    setRecommendation({ ...recommendation, binderOption: value });
    console.log("Success:", value);
  };
  const { isLoading, isSuccess, data, refetch } = useQuery(
    "risks",
    fetch_risks
  );

  const {
    isLoading: isLoadingCodes,
    isSuccess: isSuccessCodes,
    data: specialCodes,
  } = useQuery("specialCodes", fetch_codes);

  return (
    <div>
      {isLoading && isLoadingCodes && <Loader />}
      {isSuccess && isSuccessCodes && (
        <Form
          id="recommendationForm"
          layout="vertical"
          onFinish={(values) => onFinish(values)}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Recommendation Level"
            name="recommendation_level"
            rules={[
              {
                required: true,
                message: "Please add recommendation Level",
              },
            ]}
          >
            <Rate allowHalf />
          </Form.Item>
          <Form.Item label="Applicable Risks" name="risk">
            <Select
              showSearch
              mode="multiple"
              placeholder="Select risk(s)"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {data.map((option) => {
                // let _option = JSON.parse(`${option.risk_details}`);
                // console.log('option', _option);
                return (
                  <Option
                    value={option.risk_system_ref}
                    key={option.risk_system_ref}
                  >
                    {option.risk_details["Car Make"]}-
                    {option.risk_details["Car Model"]}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Remarks"
            name="recommendation_remarks"
            rules={[
              {
                required: true,
                message: "Please add remarks",
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            label="Add Binder to this product?"
            name="binderOption"
            rules={[
              {
                required: true,
                message: "Please select an option",
              },
            ]}
          >
            <Radio.Group
              onChange={onBinderOptionChange}
              value={recommendation.binderOption}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {recommendation.binderOption && (
            <Form.Item
              label="Select a binder for the product selected"
              name="special_code"
            >
              <Select
                showSearch
                // style={{ width: 200 }}
                placeholder="Select or Search a binder"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {specialCodes.map((code) => {
                  if (
                    Number(code.product_id) ===
                    Number(recommendation.product_id)
                  ) {
                    return (
                      <Option value={code.token_code}>
                        {code.token_description}
                      </Option>
                    );
                  } else return null;
                })}
              </Select>
            </Form.Item>
          )}
        </Form>
      )}
    </div>
  );
};

export default ProductRecommendation;
