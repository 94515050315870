import React, { useContext, useEffect } from 'react';
import { DashboardContext } from 'context/DashboardContext';
import { ModalContext } from 'context/ModalContext';
import Moment from 'react-moment';
import { Tabs, Descriptions, Tooltip, List, Avatar, Image, Rate, Statistic } from 'antd';
import * as Icon from 'react-feather';
//import './policy.css';
import CicProduct from 'assets/images/cic-lady-auto.jpg';
import JubileeProduct from 'assets/images/ladyJubilee.jpg';
import Robert from 'assets/images/avatar2.png';
import AddRecommendation from 'components/widgets/modals/AddRecommendation';

export default function View_Quotation() {
	const { listQuotations } = useContext(DashboardContext);
	const { setModalShow, modalShow, modalClose } = useContext(ModalContext);
	const { TabPane } = Tabs;

	const documents = [
		{
			title: 'Document 1',
		},
		{
			title: 'Document 2',
		},
	];

	return (
		<div id="main-wrapper">
			<div className="pageheader pd-t-25 pd-b-35">
				<div className="breadcrumb pd-0 mg-0">
					<a className="breadcrumb-item" href="index.html">
						<i className="icon ion-ios-home-outline" /> Home
					</a>
					<a className="breadcrumb-item active" href>
						Dashboard
					</a>
				</div>
				<div className="card ">
					<div className="card-body d-flex flex-wrap justify-content-between ">
						<div>
							<p className="pd-0 mg-0 text-secondary float-left pr-3">
								Quotation Number <span className="d-block text-primary">#1234</span>
							</p>
							<span className="badge bg-success text-white ">
								<Icon.Clock size={10} className="mr-1" />
								Awaiting Confirmation
							</span>
						</div>
						<div>
							<p className="pd-0 mg-0 text-secondary pr-3">
								Category <span className="d-block text-primary">Motor Private</span>
							</p>
						</div>
						<div>
							<p className="pd-0 mg-0 text-secondary pr-3">
								Client Name <span className="d-block text-primary">Robert Muthumbi</span>
							</p>
						</div>
						<div>
							<p className="pd-0 mg-0 text-secondary pr-3">
								Contact Info{' '}
								<span className="d-block text-primary">
									<Icon.Phone className="mr-2" size={14} />
									<Icon.Mail size={14} />
								</span>
							</p>
						</div>
						<AddRecommendation show={modalShow} onHide={modalClose} />
						<div className="dropdown float-right">
							<button
								className="btn btn-primary dropdown-toggle"
								type="button"
								id="dropdownMenuButton"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								Actions
							</button>
							<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
								<li>
									<a onClick={() => setModalShow(true)} className="dropdown-item tx-12-force" href>
										<Icon.CheckSquare size={16} className="mr-1" color="#001f5f" />
										Recommend a product(s)
									</a>
								</li>
								<li>
									<a className="dropdown-item tx-12-force" href>
										<Icon.Info size={16} className="mr-1" color="#ffc107" /> Request Additional Info
									</a>
								</li>
								<li>
									<a className="dropdown-item tx-12-force" href>
										<Icon.Send size={16} className="mr-1" color="green" />
										Send to Client
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<Tabs type="card">
				<TabPane tab="Client Details" key="1">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-md-3 border-right ">
									<div className="d-flex flex-wrap align-items-center justify-content-center">
										<div>
											<Image width={150} src={Robert} />
											<h6 className="pt-3">Robert Njoroge Muthumbi</h6>
										</div>
									</div>
								</div>
								<div className="col-md-9">
									<Descriptions title="Overview" layout="vertical" size="small">
										<Descriptions.Item span={0.8} label="Gender">
											Male
										</Descriptions.Item>
										{/* <Descriptions.Item span={0.8} label="Phone Number">0723456789</Descriptions.Item>
    <Descriptions.Item span={0.8} label="Email AAdress">test@nexus.com</Descriptions.Item> */}
										<Descriptions.Item span={0.8} label="City">
											Nairobi
										</Descriptions.Item>
										<Descriptions.Item span={0.8} label="Date of Registration">
											12/01/2021
										</Descriptions.Item>
										<Descriptions.Item label="Address">Nairobi</Descriptions.Item>
										<Descriptions.Item label="Remark">empty</Descriptions.Item>
									</Descriptions>
								</div>
							</div>
						</div>
					</div>
				</TabPane>
				<TabPane tab="Request Details" key="2" className="clearfix">
					<div className="card">
						<div className="card-body">Request details will appear here</div>
					</div>
				</TabPane>
				<TabPane tab="Risk Details" key="3">
					<p className="badge toast-info text-white float-right">
						<a href>
							<Icon.PlusCircle size={10} className="mr-1" />
							Add Risk
						</a>
					</p>

					<div className="card ">
						<div className="card-body">
							<div className="row">
								<div className="col-md-9 border-right">Risk details will appear</div>
								<div className="col-md-3">
									<b className="text-dark">Attached Documents</b>
									<List
										itemLayout="horizontal"
										dataSource={documents}
										renderItem={(item) => (
											<List.Item>
												<List.Item.Meta
													avatar={<Avatar src={<Icon.File />} />}
													title={<a href>{item.title}</a>}
													description="Sample description"
												/>
											</List.Item>
										)}
									/>
								</div>
							</div>
						</div>
					</div>
				</TabPane>
				<TabPane tab="Quotation Details" key="4">
					<div className="card">
						<div className="card-body">
							Quotation details will appear here
							<div className="mt-3 border card-body">
								<h6 className="text-primary font-weight-bold mb-5">
									Recommendations{' '}
									<Tooltip placement="topLeft" title="Edit">
										<a href>
											<Icon.Edit3 size={14} />
										</a>
									</Tooltip>
								</h6>
								<div className="card mb-2 bg-light">
									<div className="card-body">
										<span className="badge rounded-pill bg-warning recommend-badge">
											Highly Recommended
										</span>
										<Tooltip placement="topLeft" className="float-right" title="Remove">
											<a href>
												<Icon.Trash2 color="red" size={14} />
											</a>
										</Tooltip>
										<div className="d-flex flex-wrap col-md-9 m-2 justify-content-between">
											<div>
												<Image width={150} src={CicProduct} />
											</div>
											<div>
												<h6>CIC Lady Auto</h6>
												<small>
													<span className="d-block">Recommendation Level</span>
													<Rate disabled allowHalf defaultValue={4.5} />{' '}
												</small>
												<div className="mt-2">
													<p className="text-dark p-0 m-0">Key Features</p>
													<ul className="m-0 p-0">
														<li>
															<Icon.CheckSquare
																className="mr-1"
																size={14}
																color="#3f8600"
															/>
															Courtesy Car
														</li>
														<li>
															<Icon.CheckSquare
																className="mr-1"
																size={14}
																color="#3f8600"
															/>
															Excess Protector
														</li>
														<li>
															<Icon.CheckSquare
																className="mr-1"
																size={14}
																color="#3f8600"
															/>
															A personal Accident cover
														</li>
														<li>
															<Icon.CheckSquare
																className="mr-1"
																size={14}
																color="#3f8600"
															/>
															24hr roadside assistance services
														</li>
														<li>
															<Icon.CheckSquare
																className="mr-1"
																size={14}
																color="#3f8600"
															/>
															Forced ATM Withdrawal Maximum Limit Ksh 15 000/
														</li>
													</ul>
												</div>
											</div>
											<div>
												<p>Price</p>
												<h6
													className="text-decoration-line-through text-danger"
													style={{ textDecoration: 'line-through' }}
												>
													KES.42500
												</h6>
												<h3 className="text-success">
													KES.32500<span className="tx-14-force">.00</span>
												</h3>

												<p className="text-info ">
													<Icon.Tag size={14} className="mr-1" /> Discounted by 23.5%
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="card mb-2 bg-light">
									<div className="card-body">
										<Tooltip placement="topLeft" className="float-right" title="Remove">
											<a href>
												<Icon.Trash2 color="red" size={14} />
											</a>
										</Tooltip>
										<div className="d-flex flex-wrap col-md-9 m-2 justify-content-between">
											<div>
												<Image width={150} src={JubileeProduct} />
											</div>
											<div>
												<h6>Lady Jubilee Car Insurance</h6>
												<small>
													<span className="d-block">Recommendation Level</span>
													<Rate disabled allowHalf defaultValue={4} />
												</small>
												<div className="mt-2">
													<p className="text-dark p-0 m-0">Key Features</p>
													<ul className="m-0 p-0">
														<li>
															<Icon.CheckSquare
																className="mr-1"
																size={14}
																color="#3f8600"
															/>
															Hand bag contents
														</li>
														<li>
															<Icon.CheckSquare
																className="mr-1"
																size={14}
																color="#3f8600"
															/>
															Personal Accident for Insured
														</li>
														<li>
															<Icon.CheckSquare
																className="mr-1"
																size={14}
																color="#3f8600"
															/>
															Preloaded oil Libya Fuel card
														</li>
														<li>
															<Icon.CheckSquare
																className="mr-1"
																size={14}
																color="#3f8600"
															/>
															Forced ATM Withdrawal
														</li>
													</ul>
												</div>
											</div>
											<div>
												<p>Price</p>
												{/* <h6 className="text-decoration-line-through text-danger" style={{textDecoration: "line-through"}}>KES.42500</h6> */}
												<h3 className="text-success">
													KES.39500<span className="tx-14-force">.00</span>
												</h3>

												{/* <p className="text-info "><Icon.Tag size={14} className="mr-1"/> Discounted by 23.5%</p>    */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</TabPane>
			</Tabs>
		</div>
	);
}
