import React, { useContext } from "react";

import { Button } from "antd";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import styles from "./Profession.module.css";
import { ModalContext } from "context/ModalContext";
import AddProfession from "components/widgets/modals/AddProfession";
import NoData from "components/no-data/NoData";
import { base_url } from "services/apiSrv";
import routes from "routes";

export default function Profession({ data }) {
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

  return (
    <>
      <div className="pageheader">
        <div className="breadcrumb pd-0 mg-0 pd-b-20">
          <Link className="breadcrumb-item active" to={routes.dashboard}>
            <i className="icon ion-ios-home-outline " /> Home
          </Link>
          <Link className="breadcrumb-item ">Specialization</Link>
        </div>
        <h5>My Specialization(s)</h5>
      </div>

      <div className="clearfix">
        <Button
          className="btn btn-blue my-3 float-right text-white"
          onClick={() => setModalShow(true)}
        >
          Add Specialization
        </Button>
      </div>
      {data.length === 0 ? (
        <NoData message="You currently dont have any professions" />
      ) : (
        <main className={styles["page-content"]}>
          {data.map((profession, i) => (
            <motion.div
              className={styles.card}
              key={profession.code}
              style={{ backgroundImage: `url(${base_url + profession.icon})` }}
              initial={{ opacity: 0, translateX: -50, translateY: -50 }}
              animate={{ opacity: 1, translateX: 0, translateY: 0 }}
              transition={{ duration: 0.3, delay: i * 0.3 }}
            >
              <div className={styles.content}>
                <h2 className={styles.title}>{profession.name}</h2>
                <button className={styles.btn}>Remove</button>
              </div>
            </motion.div>
          ))}
        </main>
      )}

      {/* modal */}
      <AddProfession show={modalShow} onHide={modalClose} />
    </>
  );
}
