import React, { useContext } from "react";
import { DashboardContext } from "context/DashboardContext";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { Pagination, Input, Tabs, Alert } from "antd";
import Moment from "react-moment";
import NoData from "components/no-data/NoData";
import QuotationReference from "components/widgets/modals/QuotationReference";

export default function Quotations({
  handle_view_quotation,
  add_asset,
  search,
  searchSpace,
  data,

}) {
  const {
    paginate,
    numEachPage,
    handlePaginationChange,
    listQuotations,
  } = useContext(DashboardContext);

  const { Search } = Input;
  const { TabPane } = Tabs;

  const items = data.filter((data) => {
      if (search == '') return data;
      else if (
        data?.names?.toLowerCase().includes(search.toLowerCase()) ||
        data?.created_date?.toLowerCase().includes(search.toLowerCase()) ||
        data?.quotation_reference?.toLowerCase().includes(search.toLowerCase()) ||
        data?.client_quotation_status?.toLowerCase()
          .includes(search?.toLowerCase())||
         data?.quotation_amount?.toString().toLowerCase().includes(search?.toLowerCase())
      ) {
        return data;
      }
    })
    .slice(paginate.minValue, paginate.maxValue)
    .map((row) => {
       return(
        <tr key={row.client_req_id} id={row.client_req_id} details={row}>
        <td className="d-flex align-items-center">
          <div className=" mr-4">
            <img
              src="https://api.nexus.ke/static/bank/docs/7c7637c12004f362be9f286c4e260683.jpg"
              className="img-fluid wd-35 ht-35 rounded-circle"
              alt=""
            />
          </div>
          <div className="d-block">
            <a href="" className="my-0 mt-1 ">
              {row?.client[0]?.names}
            </a>
          </div>
        </td>
        <td>
          <Moment format="DD MMM YYYY" date={row.created_date} />
        </td>

        <td>
          <span
            className={`badge ${
              row.client_quotation_status === "PENDING:IN-PROGRESS"
                ? "badge-info"
                : row.client_quotation_status === "PENDING:PURCHASE"
                ? "badge-warning"
                : row.client_quotation_status === "CLOSED:REJECTED"
                ? "badge-danger"
                : "badge-success"
            }`}
          >
            {row.client_quotation_status}
          </span>
        </td>
        <td>{row.quotationRef}</td>
        <td>KES. {row.quotationAmount}</td>

        <td>
        <a
              href=""
              className="btn btn-sm btn-label-primary"
              onClick={() =>  handle_view_quotation(row.client_quotation_id)}
            >
              View
            </a>
         
        </td>
      </tr>
        )})

        console.log("items", items)

  return (
    <>
      <div className="pageheader pd-t-25 pd-b-35">
        <div className="breadcrumb pd-0 mg-0">
          <a className="breadcrumb-item" href="index.html">
            <i className="icon ion-ios-home-outline" /> Home
          </a>
          <a className="breadcrumb-item active" href="">
            Dashboard
          </a>
        </div>
      </div>
   

      <div className="pd-t-5 pd-b-5 pd-l-5">
        <h1 className=" tx-20">
          <Icon.FileText size={34} className="pr-2 text-primary" />
          My Quotations
        </h1>
      </div>
      {data.length === 0  ? (
        
        <NoData message="You currently don't have  any quotations" />
      ) :  (
        <>
        
         
              <div className="row row-xs clearfix">
                <div className="col-lg-12 card rounded-10 col-xl-12">
                  <div className="mg-b-20">
                    <div className="card-header">
                      <Search
                        placeholder="Search quotation"
                        type="search"
                        onChange={(e) => searchSpace(e)}
                        className="ml-md-auto mg-r-10"
                        style={{ width: 200 }}
                      />
                    </div>
                    <div className="mg-b-20">
                      <div
                        className="card-body pd-0 collapse show"
                        id="customerDetails"
                      >
                        <div className="table-responsive">
                          <table className="table table-hover mg-0">
                            <thead className="tx-dark tx-uppercase tx-12 tx-bold">
                              <tr className="bg-light">
                                <th className="pl-5">Client</th>
                                <th>Request Date</th>
                                <th>Status</th>
                                <th>Quotation Reference</th>
                                <th>Quotation Amount</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                 
                        {items}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <Pagination
                      className="ml-auto"
                      responsive={true}
                      dataSource={data}
                      total={data.length}
                      showTotal={(total) => `Total ${total} items`}
                      defaultPageSize={numEachPage}
                      defaultCurrent={1}
                      onChange={handlePaginationChange}
                    />
                  </div>
                </div>
              </div>
        </>
      )}
    </>
  );
}
