import { requiredRule } from "utils/inputValidationRules";
import {
  createTextAreaFieldConfig,
  createMultipleSelectFormFieldConfig,
} from "utils/formConfig";

export function requestUnderwritterForm(options) {
  let form = {
    config: {
      buttonVariant: "success",
      buttonText: "Make Request",
    },
    fields: {
      underwriter_name: {
        ...createMultipleSelectFormFieldConfig(
          "Select Underwritter",
          "underwriter_name",
          options,
        ),
        validationRules: [requiredRule("underwriter_name")],
      },
      // remarks: {
      //   ...createTextAreaFieldConfig("Remarks", "remarks", "Please add me as your preffered intermediary", true),
      //   // validationRules: [""],
      // },
    },
  };
  return form;
}
