import React, { useState } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { api_srv } from "services";
import { show_toast } from "utils/helpers";

const UploadComponent = ({ value = {}, onChange, accept = "application/pdf" }) => {
  const [defaultFileList, setDefaultFileList] = useState([]);
  //const [imageUrl, setImageUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  const triggerChange = (changedValue) => {
    onChange(changedValue);
  };
  const props = {
    beforeUpload: (file) => {
      if (file.type !== "application/pdf") {
        show_toast(`${file.name} is not a PDF file`, "error");
        return Upload.LIST_IGNORE;
      }
      return true;
    },
  };
  const handleUploadChange = ({ file, fileList, event }) => {
    setDefaultFileList(fileList);
  };

  // const uploadImage = async (options) => {
  //   const { onSuccess, onError, file } = options;
  //   try {
  //     const res = await (await api_srv).upload_api("image", file);
  //     onSuccess("Ok");

  //     console.log("server res: ", res);
  //     setImageUrl(res.file_path);
  //     triggerChange(res.file_path);
  //   } catch (err) {
  //     let error = await err;
  //     console.log("Eroor: ", error);
  //     //   const error = new Error("Some error");
  //     onError({ err });
  //   }
  // };

    const uploadFile = async (options) => {
    const { onSuccess, onError, file } = options;
    try {
      const res = await (await api_srv).upload_api("pdf", file);
      onSuccess("Ok");

      console.log("Server response: ", res);
      setFileUrl(res.file_path);
      triggerChange(res.file_path);
    } catch (err) {
      let error = await err;
      console.log("Error: ", error);
      onError({ err });
    }
  };

  return (
    <div>
      <Upload
        {...props}
        accept={accept}
        value={fileUrl}
        customRequest={uploadFile}
        onChange={handleUploadChange}
        listType="text"
        defaultFileList={defaultFileList}
        className="upload-list-inline"
      >
        {defaultFileList.length >= 1 ? null : (
          <Button icon={<UploadOutlined />}>Upload</Button>
        )}
      </Upload>
    </div>
  );
};

export default UploadComponent;
