import React from "react";
import "./input.css";

function InputField({
  label,
  type,
  name,
  handleChange,
  errorMessage,
  isValid,
  value,
  rows = "3",
  hidden
}) {

  
  
  return (
    <div className="form-group">
      {!hidden && <label htmlFor={name}>{label}</label>}
      {!hidden && (
        <textarea
          name={name}
          value={value}
          onChange={handleChange}
          rows={rows}
          className="d-block form-control"
        />
      )}
      {errorMessage && !isValid && (
        <span className="error">{errorMessage}</span>
      )}
    </div>
  );
}

export default React.memo(InputField);
