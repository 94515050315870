import React from 'react';
import ReactDOM from 'react-dom';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'shepherd.js/dist/css/shepherd.css';

import App from './App';
import * as serviceWorker from 'serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { KycContextProvider } from 'context/KycContext';
import { ModalContextProvider } from 'context/ModalContext';
import { DirectorContextProvider } from 'context/DirectorContext';
import { AlertsContextProvider } from 'context/AlertsContext';
import { AuthContextProvider } from 'context/AuthContext';
import { DashboardContextProvider } from 'context/DashboardContext';

const queryClient = new QueryClient();

ReactDOM.render(
	<QueryClientProvider client={queryClient}>
		<Router>
			<ToastContainer />
			<AlertsContextProvider>
				<KycContextProvider>
					<ModalContextProvider>
						<DashboardContextProvider>
							<DirectorContextProvider>
								<AuthContextProvider>
									<App />
								</AuthContextProvider>
							</DirectorContextProvider>
						</DashboardContextProvider>
					</ModalContextProvider>
				</KycContextProvider>
			</AlertsContextProvider>
		</Router>
	</QueryClientProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
