import React, { useContext, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { Button } from 'antd';

import { KycContext } from 'context/KycContext';
import { ModalContext } from 'context/ModalContext';
import SelectProduct from 'components/stepper/request-stepper/Select_Product';
import InsuranceClass from 'components/stepper/request-stepper/Select_Class';
import ProductRecommendation from 'components/stepper/request-stepper/Product_Recommendation';

const AddRecommendation = (props) => {
	// access context

	const { setCurrent, current, next, prev } = useContext(KycContext);
	const { setModalShow } = useContext(ModalContext);


	// on modal close clear state

	const close = () => {
		setModalShow(false);
		setCurrent(0);
	};

	const steps = [
		{
			title: 'Select Insurance Class',
			content: <InsuranceClass />,
		},
		{
			title: 'Select a product',
			content: <SelectProduct />,
		},
		{
			title: 'Recommended Product',
			content: (
				<ProductRecommendation
					systemRef={props.systemRef}
					refetchRecommendations={props.refetchRecommendations}
					quotationRequestRef={props.quotationRequestRef}
				/>
			),
		},
	];

	return (
		<div>
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				backdrop="static"
				keyboard={false}
				onHide={() => close()}
			>
				<Modal.Header className="bg-light" closeButton>
					<span className="">{steps[current].title}</span>
				</Modal.Header>
				<Modal.Body className="">
					<div className="steps-content ">
						<span>{`Step ${current + 1} of ${steps.length}`}</span>
						{console.log('steps here', steps[current])}
						{steps[current].content}
					</div>
				</Modal.Body>
				<Modal.Footer>
					{current > 0 && (
						<Button
							className="btn-blue float-right mt-5 mb-3 text-white  px-4 py-1 rounded"
							onClick={() => prev()}
						>
							Back
						</Button>
					)}
				   {current === 2 &&  (
						<Button
							className="btn-blue float-right mt-5 mb-3 text-white px-4 py-1 rounded"
							form="recommendationForm"
							key="submit"
							htmlType="submit"
						>
							Add Recommendation
						</Button>
					)}

					{current !== steps.length - 1 && current > 0 && (
						<Button
							className="btn-blue float-right mt-5 mb-3 text-white  px-4 py-1 rounded"
							onClick={() => next()}
						>
							Next
						</Button>
					)}
					{current === 0 && (
						<Button
							className="btn-blue float-right mt-5 mb-3 text-white  px-4 py-1 rounded"
							form="insuranceClass"
							htmlType="submit"
						>
							Next
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default AddRecommendation;
