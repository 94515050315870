import React, { useEffect, useContext } from "react";

import { useQuery } from "react-query";

import Wallet from "./Wallet";
import { DashboardContext } from "../../context/DashboardContext";
import { api_srv } from "../../services";
import Loader from "components/widgets/loader";

const WalletContainer = () => {
  const { accountTrans, setAccountTrans } = useContext(DashboardContext);

  const get_wallet_info = async () => {
    let resp = await (await api_srv).fetch_wallet_info();
    return resp.entity_accounts;
  };

  const { isLoading, isSuccess, data } = useQuery("wallet", get_wallet_info);

  async function view_account_transactions(account_number) {
    setAccountTrans([]);
    try {
      let resp = await (await api_srv).get_transactions(account_number);
      setAccountTrans(resp.transaction);
    } catch (err) {
      let error = await err;
      console.log("error fetching account transactions", error);
      // addToast(error,{
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  }
  return (
    <>
      {isLoading && <Loader />}
      {isSuccess && (
        <Wallet
          wallet={data}
          target="dashboard"
          accountTrans={accountTrans}
          view_account_transactions={view_account_transactions}
        />
      )}
    </>
  );
};

export default WalletContainer;
