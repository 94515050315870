import React, { useContext, useEffect, useRef } from "react";

import { Button, List, Avatar, Spin } from "antd";
import { useQuery } from "react-query";
import { Scrollbars } from "react-custom-scrollbars";
import { LoadingOutlined } from "@ant-design/icons";
import { base_url } from "services/apiSrv";

import { KycContext } from "context/KycContext";
import { DashboardContext } from "context/DashboardContext";
import { api_srv } from "services";
import { AlertsContext } from "context/AlertsContext";
import "./style.css";
import Alert from "../../alerts/WarningAlert";

export default function SelectClass() {
  const { next, prev } = useContext(KycContext);
  const { showAlert, setShowAlert } = useContext(AlertsContext);
  const { recommendation, setRecommendation } = useContext(DashboardContext);

  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
  
  // Default insurance class
  const DEFAULT_CLASS = "G074920";
  const itemRefs = useRef({}); // Store refs for each item
  const scrollbarRef = useRef(null); // Ref for Scrollbars

  const fetch_subclass = async () => {
    let resp = await (await api_srv).get_subclass();
    console.log("THE SUBCLASSES", resp.insurance_sub_classes);
    return resp.insurance_sub_classes;
  };
  const check = () => {
    recommendation.insuranceClass === "" ? setShowAlert(true) : next();
  };

  function handleClassChange(e) {
    setRecommendation({
      ...recommendation,
      insuranceClass: e.target.value,
      insuranceClassName: e.target.id,
    });
  }
    
  // Preselect the default class on first render
    useEffect(() => {
      if (!recommendation.insuranceClass) {
        setRecommendation((prev) => ({
          ...prev,
          insuranceClass: DEFAULT_CLASS,
        }));
      }
    }, [setRecommendation, recommendation.insuranceClass]);

  const { data, isLoading, isSuccess } = useQuery("subclasses", fetch_subclass);

    // Scroll to the selected insurance class
    useEffect(() => {
      if (recommendation.insuranceClass && itemRefs.current[recommendation.insuranceClass]) {
        const selectedElement = itemRefs.current[recommendation.insuranceClass];
  
        if (scrollbarRef.current && selectedElement) {
          scrollbarRef.current.scrollTop(selectedElement.offsetTop - 100);
        }
      }
    }, [recommendation.insuranceClass, data]);
  

  return (
    <div>
      {isLoading && (
        <div
          style={{
            textAlign: "center",
            borderRadius: "4px",
            marginBottom: "20px",
            padding: "30px 50px",
            margin: "20px 0",
          }}
        >
          <Spin indicator={antIcon} />
        </div>
      )}
      {isSuccess && (
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          style={{ height: 400 }}
          ref={scrollbarRef}
        >
          {showAlert && (
            <Alert
              message="Select an insurance class to proceedd"
              variant="danger"
            />
          )}
          <form
            id="insuranceClass"
            onSubmit={(e) => {
              e.preventDefault();
              check();
            }}
          >
            <div className="funkyradio">
              {data.map((item) => (
                <div ref={(el) => (itemRefs.current[item.code] = el)} className="funkyradio-success" key={item.sub_class_id}>
                  <input
                    type="radio"
                    name="insuranceClass"
                    id={item.name}
                    value={item.code}
                    onChange={handleClassChange}
                    checked={recommendation.insuranceClass === item.code}
                  />
                  <label htmlFor={item.name}>
                    <List.Item>
                      <List.Item.Meta
                        style={{ display: "block" }}
                        avatar={<Avatar src={base_url + item.icon} />}
                        title={<a href>{item.name}</a>}
                        description={item.description}
                      />
                    </List.Item>
                  </label>
                </div>
              ))}
            </div>
          </form>
        </Scrollbars>
      )}
    </div>
  );
}
