import React, { useContext, useState, useEffect } from 'react';
import Appointments from './Appointments';
import { DashboardContext } from 'context/DashboardContext';
import { ModalContext } from 'context/ModalContext';
import { createEventId } from 'utils/appointment-utils';
import { fetch_appointments, close_appointment, fetch_appointment_details, update_appointment_new_status } from 'services/apiSrv';
import Loader from 'components/widgets/loader';
import { message } from 'antd';
import { useQuery } from 'react-query';
import Moment from 'react-moment';

const AppointmentContainer = () => {
	const { currentEvents, setCurrentEvents, selectedObject, appointment, setAppointment, setSelectedObject, refreshAppointments, setRefreshAppointments } =
		useContext(DashboardContext);
	const { setModalShow } = useContext(ModalContext);

	const [weekendsVisible, setWeekendsVisible] = useState(true);
	const [appointmentId, setAppointmentId] = useState('');

	const [initialEvents, setInitialEvents] = useState([]);
	const [clicked, setClicked] = useState(false);
	const [appointmentShow, setAppointmentShow] = useState(false);

	const hide = () => {
		setClicked(false);
	};
	const modalAppointmentsClose = () => {
		setAppointmentShow(false);
	    setRefreshAppointments(true);

	};

	const handleClickChange = () => {
		setAppointmentShow(true);
	};

	const _appointments = async () => {
		let resp = await fetch_appointments();
		//console.log('1ST THE APPOINTMENTS', resp.intermediary_appointments);
		setCurrentEvents(resp.intermediary_appointments);
		
		if(!!resp.intermediary_appointments){
            setRefreshAppointments(true);
		}

		return resp.intermediary_appointments;
	};

	
	const { isLoading, isError, isSuccess, data, refetch } = useQuery('appointments', _appointments, {
		onSuccess: () => setRefreshAppointments(false), // Reset after successful fetch
	});
	
	useEffect(() => {
		if (refreshAppointments) {
			refetch();
		}
	}, [refreshAppointments, refetch]);

	let newArray = [];
	isSuccess &&
		Object.keys(data).map((key) => {
			newArray.push({
				id: data[key].intermediary_appointment_id,
				title: data[key].appointment_subject,
				start: data[key].appointment_date,
				status: data[key].appointment_status,
			});
			console.log("DAAAAAATA", data)
		});
		//console.log ("new Array", newArray)
	const handleWeekendsToggle = () => {
		setWeekendsVisible(!weekendsVisible);
	};

	const handleDateSelect = async (selectInfo) => {
		var selectedDate = selectInfo.start;
		var CurrentDate = new Date().getDate();
		var selectDate = new Date(selectedDate).getDate();
		console.log('the date selected', selectedDate);
		if (selectDate >= CurrentDate) {
			try {
				setAppointment({ ...appointment, date: selectedDate });
				await setSelectedObject(selectInfo);
				setModalShow(true);
			} catch (err) {
				console.log('Appointment Modal error', err);
			}
		} else {
			message.error('Events cannot be added to this date');
		}
	};

	const handleEventClick = async (id) => {
		console.log('the event clicked', id.event._def.publicId);
		refetch();
		setAppointmentShow(true);

		setClicked(true);
		update_appointment_new_status(id.event._def.publicId)
		setRefreshAppointments(true);

		sessionStorage.setItem('appointmentId', id.event._def.publicId);
		setAppointmentId(id.event._def.publicId);

		// Force page reload
	    //window.location.reload();
	};

	function addEvent(selectInfo) {
		console.log('the select info', selectInfo);
	}

	const handleRemove = async (id) => {
		try {
			let resp = await close_appointment(id);
			console.log('successfully closed appointment', resp);
		} catch (err) {
			let error = await err;
			console.log('error removing appointment', error);
		}
	};

	const _appointmentDetails = async (id) => {
		console.log('TRY THE ID', id);
		const queryId = id.queryKey[1]
		
		let resp;
		if(queryId){
			resp = await fetch_appointment_details(id);
			//setRefreshAppointments(true);
			console.log('appointment details', resp);
			return resp.appointment;
		}
	};


	const {
		data: details,
		isLoading: detailsLoading,
		isSuccess: successDetails,
		isError: errorDetails,
	} = useQuery(['appointment Details', sessionStorage.getItem('appointmentId')], _appointmentDetails, {
		refetchOnWindowFocus: false,
		enabled: !!appointmentId
		
	});


	React.useEffect(() => {
		if (appointmentId) {
			refetch();
		}
	}, [appointmentId]);
	
	//console.log("logg d", details)
	//console.log("success", successDetails)

	return (
		<>
			{isLoading && <Loader />}
			{isSuccess && (
				<Appointments
					appointmentId={appointmentId}
					state={newArray}
					hide={hide}
					handleWeekendsToggle={handleWeekendsToggle}
					handleDateSelect={handleDateSelect}
					handleEventClick={handleEventClick}
					addEvent={addEvent}
					weekendsVisible={weekendsVisible}
					handleRemove={handleRemove}
					handleClickChange={handleClickChange}
					clicked={clicked}
					modalAppointmentsClose={modalAppointmentsClose}
					appointmentShow={appointmentShow}
					details={details}
					detailsLoading={detailsLoading}
					successDetails={successDetails}
					errorDetails={errorDetails}
				/>
			)}
		</>
	);
};

export default AppointmentContainer;
