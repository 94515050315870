import React, { useContext, useState } from "react";

import * as Icon from "react-feather";
import { Pagination, Avatar, Popconfirm } from "antd";
import styled from "styled-components";

import NoData from "components/no-data/NoData";
import {
  numberWithCommas,
  getInitials,
  formatDate,
  isFunction,
  convertCurrency,
} from "utils/helpers";
import { base_url } from "services/apiSrv";

const TableComponent = (props) => {
  // const { handlePaginationChange } = useContext(DashboardContext);
  function getNestedObject(nestedObj, pathArr) {
    return pathArr.reduce(
      (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
      nestedObj
    );
  }
  const {
    icon,
    hasAction = "false",
    table_fields = [],
    table_data = [],
    row_data = [],
    search_fields = [],
    title = "Table Title",
    table_class = "",
    pageSize = 10,
  } = props;

  const TableImage = styled.img`
    height: 40px;
    width: 40px;
    object-fit: contain;
    border-radius: 50%;
  `;

  const [paginationData, setPaginationData] = useState({
    totalPage: table_data.length / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });

  const handlePaginationChange = (page) => {
    setPaginationData({
      ...paginationData,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
  };

  return (
    <div>
      <div className="mg-b-20">
        <div className="card-body pd-0 collapse show">
          <div className="table-responsive">
            <h1 className=" tx-20">
              {/* <Icon.Briefcase size={36} className="pr-2  text-primary" /> */}

              {title}
            </h1>

            {table_data.length === 0 ? (
              <NoData message="No Data" />
            ) : (
              <table
                className="table table-hover mg-0"
                style={{ border: "1px solid #ddd" }}
              >
                <thead className="tx-dark tx-uppercase tx-12 tx-bold">
                  <tr style={{ background: "linear-gradient(#eee,#fff)" }}>
                    {table_fields.map((field, i) => (
                      <th key={i}>{field.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {table_data?.map(
                    (d, i) =>
                      i >= paginationData.minIndex &&
                      i < paginationData.maxIndex && (
                        <tr key={i}>
                          {row_data?.map((_, i) => (
                            <td
                            //  data-label={table_fields[i - 1].name}
                            >
                              {/* Images */}
                              {row_data[i].type === "image" ? (
                                <Avatar
                                  size={40}
                                  src={`${
                                    d[row_data[i].domain] || base_url || ""
                                  }${d[row_data[i].field]}`}
                                  style={{
                                    color: "#001f5f",
                                    backgroundColor: "rgba(5,88,183,0.10)",
                                  }}
                                >
                                  <small>
                                    {getInitials(d[row_data[1].field])}
                                  </small>
                                </Avatar>
                              ) : // Single field text
                              row_data[i].type === "text" ? (
                                <div className="bigger mt-2">
                                  {getNestedObject(
                                    d,
                                    row_data[i].field.split(".")
                                  ) || "-"}
                                </div>
                              ) : //    Single field number
                              row_data[i].type === "number" ? (
                                <div>
                                  {numberWithCommas(
                                    Math.round(
                                      Number(d[row_data[i].field]) * 100
                                    ) / 100
                                  )}
                                </div>
                              ) : row_data[i].type === "currency" ? (
                                <div>
                                  {convertCurrency(d[row_data[i].field])}
                                </div>
                              ) : // Nested field text
                              row_data[i].type === "nested" ? (
                                <>
                                  {row_data[i].rows.map((row) => (
                                    <div>
                                      <span className={row.class}>
                                        {d[row.field] || "-"}
                                      </span>
                                    </div>
                                  ))}
                                </>
                              ) : // Status
                              row_data[i].type === "status" ? (
                                <div>
                                  <span className={`badge ${
                                    d[row_data[i].field]?.toLowerCase() === 'closed' ? 'badge-success' :
                                    d[row_data[i].field]?.toLowerCase() === 'open' ? 'badge-warning' :
                                    d[row_data[i].field]?.toLowerCase() === 'new' ? 'badge-danger' :
                                    ''
                                  }`}>
                                    {d[row_data[i].field]}
                                  </span>
                                </div>
                              ) : //Date
                              row_data[i].type === "date" ? (
                                <div>
                                  <span>
                                    {formatDate(d[row_data[i].field])}
                                  </span>
                                </div>
                              ) : // Action
                              row_data[i].type === "action" &&
                                row_data[i].actions.length === 1 ? (
                                row_data[i].actions.map(
                                  ({ method, field, title }, i) => {
                                    let param = d[field] || "";
                                    console.log('rd', d.status?.toLowerCase())

                                    //   check if method is a function

                                    return (
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-label-primary"
                                        onClick={() => isFunction(method) && method.apply(this, [param])}
                                        disabled={d?.status?.toLowerCase() === "closed"}
                                      >
                                        {title}
                                      </button>

                                    );
                                  }
                                )
                              ) : row_data[i].type === "action" &&
                                row_data[i].actions.length > 1 ? (
                                <div className="dropdown">
                                  <a
                                    href
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    className="btn btn-sm btn-label-primary"
                                  >
                                    <Icon.MoreHorizontal />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-left">
                                    {row_data[i].actions.map(
                                      ({ method, field, title, id }, i) => {
                                        let param = d[field] || "";

                                        //   check if method is a function
                                        function isFunction(obj) {
                                          return (
                                            obj !== undefined &&
                                            obj !== null &&
                                            obj.constructor === Function
                                          );
                                        }

                                        return (
                                          <li className="dropdown-item" key={i}>
                                            <a
                                              href
                                              className="dropdown-link tx-13"
                                              onClick={() =>
                                                isFunction(method) &&
                                                method.apply(this, [param])
                                              }
                                            >
                                              <Icon.Eye
                                                size={14}
                                                className="mr-2"
                                              />
                                              {title}
                                            </a>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              ) : null}
                            </td>
                          ))}
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <Pagination
        responsive={true}
        dataSource={table_data}
        total={table_data.length}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        pageSize={pageSize}
        defaultCurrent={1}
        onChange={handlePaginationChange}
        current={paginationData.current}
      />
    </div>
  );
};

export default TableComponent;
