import React from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import "./VerifyCode.css"; 

const VerifyCode = () => {
  const history = useHistory();

  const validationSchema = yup.object({
    code: yup.string().required("Code is required"),
  });

  const handleSubmit = async (values) => {
    try {
      await fetch("/verify-reset-code", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      });
      history.push("/reset-password");
    } catch (error) {
      console.error("Error verifying code", error);
    }
  };

  return (
    <div className="verify-code-container">
      <div className="verify-code-card">
        <h2 className="verify-code-title">Check Your Email</h2>
        
        <Formik initialValues={{ code: "" }} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form className="verify-code-form">
              <div className="form-group">
                <label className="form-label">Enter Code:</label>
                <Field 
                  type="text" 
                  name="code" 
                  className="form-input"
                />
                <ErrorMessage name="code" component="div" className="error-message" />
              </div>

              <div className="button-group">
                <button 
                  type="submit" 
                  className="submit-button"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Processing..." : "Submit"}
                </button>
                <button 
                  type="button" 
                  onClick={() => history.push("/forgot-password")} 
                  className="back-button"
                >
                  Back
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default VerifyCode;