import React, { useContext, useState } from "react";

import { Modal } from "react-bootstrap";
import "../../../assets/css/dashboard.css";  
import { Button, Form, Input, Space, Row, Col } from "antd";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useMutation } from "react-query";
import { MinusCircleOutlined } from "@ant-design/icons";

import { DashboardContext } from "context/DashboardContext";
import { ModalContext } from "context/ModalContext";
import { checkPin, createScheme, listSchemes } from "services/apiSrv";
import { show_toast } from "utils/helpers";
import UploadComponent from "components/uploads";
import CustomForm from "components/widgets/forms/CustomForm";
import { addSchemeForm } from "components/widgets/forms/Add_Scheme_Form";

const AddScheme = (props) => {
  const { address, setAddress, coordinates, setCoordinates } =
    useContext(DashboardContext);
  const { modalClose } = useContext(ModalContext);

  const [isPin, setIsPin] = useState(true);
  const [kra, setKra] = useState("");
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      // get the lat and lng of the  result user has selected
      const { lat, lng } = await getLatLng(results[0]);
      setCoordinates({ ...coordinates, lat: lat, lng: lng });
      setAddress(value);
    } catch (error) {}
  };

  const handleChange = (address) => {
    setAddress(address);
  };
  const postPin = useMutation(checkPin, {
    onSuccess: (data) => {
      if (data.exist === true) {
        modalClose();
        show_toast("Pin has already been used", "error");
      } else {
        setIsPin(data.exist);
        // show_toast("No scheme associated to the pin entered", "success");
      }

      console.log("SCHEME PIN RESPONSE", data);
    },
    onError: async (error) => {
      let err = await error;
      modalClose();
      console.log("Scheme pin error", err);
    },
  });
  const postScheme = useMutation(createScheme, {
    onSuccess: (data) => {
      props.refetch();
      modalClose();
      show_toast("Scheme created successfully", "success");

      console.log("SCHEME PIN RESPONSE", data);
    },
    onError: async (error) => {
      let err = await error;
      modalClose();
      console.log("Scheme pin error", err);
    },
  });
  const onSearchFinish = (values) => {
    setKra(values.kra_pin);
    postPin.mutate(values.kra_pin);
  };
  const onSchemeFinish = (values) => {
    console.log("SCHEME FORMS", values);
    // postScheme.mutate(values);
  };
  const onSubmit = (model, actions) => {
    console.log("ADD SCHEME VALUES", model);
    postScheme.mutate(model);
  };

  const onFinishFailed = (values) => {
    console.log("Failed:", values);
  };
  return (
    <div>
      <Modal
        show={props.show}
        onHide={() => {
          setIsPin(false);
          props.onHide();
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey text-center">Add a Scheme</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isPin && (
            <CustomForm
              className="form mt-md-0 mt-4"
              // validationSchema={LoginSchema}
              model={addSchemeForm}
              onSubmit={(model) => {
                onSubmit(model);
              }}
            />
            //   <Form
            //     name="scheme"
            //     onFinish={onSearchFinish}
            //     scrollToFirstError
            //     validateMessages={validateMessages}
            //     layout="vertical"
            //   >
            //     <Form.Item
            //       label="KRA PIN"
            //       name="kra_pin"
            //       labelAlign="left"
            //       rules={[{ required: true }]}
            //     >
            //       <Input />
            //     </Form.Item>

            //     <Form.Item>
            //       <Button
            //         htmlType="submit"
            //         disabled={postPin.isLoading}
            //         className="btn-blue text-white float-right mb-5  px-4 py-1 rounded "
            //       >
            //         {postPin.isLoading && (
            //           <>
            //             <i
            //               className="fa fa-circle-notch fa-spin"
            //               style={{ marginRight: "5px" }}
            //             />
            //             searching...
            //           </>
            //         )}
            //         {!postPin.isLoading && "Search"}
            //       </Button>
            //     </Form.Item>
            //   </Form>
            // )}
            // {!isPin && (
            //   <Form
            //     name="create_scheme"
            //     onFinish={onSchemeFinish}
            //     onFinishFailed={onFinishFailed}
            //     scrollToFirstError
            //     validateMessages={validateMessages}
            //     layout="vertical"
            //     initialValues={{
            //       contact_person: [""],
            //       scheme_details: "",
            //       scheme_pin: kra,
            //     }}
            //   >
            //     <Form.Item
            //       name="scheme_logo"
            //       label=" Scheme Logo"
            //       rules={[
            //         {
            //           required: true,
            //         },
            //       ]}
            //     >
            //       <UploadComponent accept=".png,.jpg" />
            //     </Form.Item>
            //     <Form.Item
            //       label="Scheme Name"
            //       name="scheme_name"
            //       labelAlign="left"
            //       rules={[{ required: true }]}
            //     >
            //       <Input />
            //     </Form.Item>
            //     <Form.Item
            //       label="Scheme Reference"
            //       name="scheme_ref"
            //       labelAlign="left"
            //       rules={[{ required: true }]}
            //     >
            //       <Input />
            //     </Form.Item>
            //     <Form.Item
            //       label="Admin Email"
            //       name="scheme_ref"
            //       labelAlign="left"
            //       rules={[{ required: true }]}
            //     >
            //       <Input />
            //     </Form.Item>
            //     <Form.Item
            //       label="KRA PIN"
            //       name="scheme_pin"
            //       labelAlign="left"
            //       rules={[{ required: true }]}
            //     >
            //       <Input disabled />
            //     </Form.Item>
            //     <Form.Item label="Contact Person(s)">
            //       <Form.List name="contact_person" rules={[{ required: true }]}>
            //         {(fields, { add, remove }) => (
            //           <>
            //             {fields.map((field) => (
            //               <Space
            //                 key={field.key}
            //                 align="baseline"
            //                 style={{ width: "100%" }}
            //               >
            //                 <Form.Item
            //                   {...field}
            //                   name={[field.name, "names"]}
            //                   fieldKey={[field.fieldKey, "names"]}
            //                   rules={[
            //                     {
            //                       required: true,
            //                       message: "Name is required",
            //                     },
            //                   ]}
            //                 >
            //                   <Input placeholder="name" />
            //                 </Form.Item>

            //                 <Form.Item
            //                   {...field}
            //                   name={[field.name, "contacts"]}
            //                   fieldKey={[field.fieldKey, "contacts"]}
            //                   rules={[
            //                     {
            //                       required: true,
            //                       message: "Contact is required",
            //                     },
            //                   ]}
            //                 >
            //                   <Input placeholder="phone number" />
            //                 </Form.Item>
            //                 <Form.Item
            //                   {...field}
            //                   name={[field.name, "email"]}
            //                   fieldKey={[field.fieldKey, "email"]}
            //                   rules={[
            //                     {
            //                       required: true,
            //                       type: "email",
            //                       message: "Email is required",
            //                     },
            //                   ]}
            //                 >
            //                   <Input placeholder="email" />
            //                 </Form.Item>

            //                 <MinusCircleOutlined
            //                   onClick={() => remove(field.name)}
            //                 />
            //               </Space>
            //             ))}

            //             <Form.Item>
            //               <Button type="dashed" onClick={() => add()} block>
            //                 Add contact
            //               </Button>
            //             </Form.Item>
            //           </>
            //         )}
            //       </Form.List>
            //     </Form.Item>

            //     <Form.List name="scheme_details">
            //       {(fields, { add, remove }) => (
            //         <>
            //           {fields.map((field) => (
            //             <Space key={field.key} align="baseline">
            //               <Form.Item
            //                 {...field}
            //                 label="Detail Name"
            //                 name={[field.name, "key"]}
            //                 fieldKey={[field.fieldKey, "key"]}
            //                 rules={[{ required: true }]}
            //               >
            //                 <Input placeholder=" name" />
            //               </Form.Item>

            //               <Form.Item
            //                 {...field}
            //                 label="Detail Value"
            //                 name={[field.name, "value"]}
            //                 fieldKey={[field.fieldKey, "value"]}
            //                 rules={[{ required: true }]}
            //               >
            //                 <Input placeholder="" />
            //               </Form.Item>

            //               <MinusCircleOutlined
            //                 onClick={() => remove(field.name)}
            //               />
            //             </Space>
            //           ))}

            //           <Form.Item>
            //             <Button type="dashed" onClick={() => add()} block>
            //               Other Details
            //             </Button>
            //           </Form.Item>
            //         </>
            //       )}
            //     </Form.List>
            //     <Form.Item
            //       name="scheme_description"
            //       label="Description"
            //       rules={[
            //         {
            //           required: true,
            //         },
            //       ]}
            //     >
            //       <Input.TextArea rows={4} />
            //     </Form.Item>

            //     <Button
            //       disabled={postScheme.isLoading}
            //       className="btn-blue text-white float-right mb-5  px-4 py-1 rounded "
            //       htmlType="submit"
            //     >
            //       {postScheme.isLoading && (
            //         <>
            //           <i
            //             className="fa fa-circle-notch fa-spin"
            //             style={{ marginRight: "5px" }}
            //           />
            //           please wait...
            //         </>
            //       )}
            //       {!postScheme.isLoading && "Save"}
            //     </Button>
            //   </Form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddScheme;
