import React, { useState, createContext, useContext, useEffect } from "react";
import { api_srv } from "../services";
import { message } from "antd";
import { ModalContext } from "../context/ModalContext";
import { AlertsContext } from "./AlertsContext";
import { KycContext } from "./KycContext";
import { useHistory } from "react-router-dom";

const DashboardContext = createContext();
const DashboardContextProvider = (props) => {
  let history = useHistory();

  // context

  const { modalClose, setModalShow } = useContext(ModalContext);
  const { setShowAlert, showAlert } = useContext(AlertsContext);
  const { next } = useContext(KycContext);

  //updating notfications 
  const [refreshAppointments, setRefreshAppointments] = useState(false);
  console.log("refresh appoinemtns dash context", refreshAppointments)

  // states

  // FORM
  const [formState, setFormState] = useState({});

  // Navbar
  const [isToggled, setToggled] = useState(false);

  const [alert, setAlert] = useState({
    message: "",
    variant: "",
  });

  const [collapsed, setCollapsed] = useState(false);

  const [info, setInfo] = useState();
  const [data, setData] = useState([]);
  const [redirState, setState] = useState(false);
  const [id, setId] = useState("");
  const [myUnderwriters, setMyUnderwriters] = useState({
    data: [],
    loading: false,
  });
  const [summary, setSummary] = useState({});
  const [pending, setPending] = useState(true);
  const [profession, setProfession] = useState({
    data: [],
    loading: false,
    hasMore: true,
  });

  const [professionList, setProfessionList] = useState({
    professions: [],
    selected: [],
    loading: false,
  });

  const [underwriterRequests, setUnderwriterRequests] = useState({
    underwriters: [],
    underwritter_id: {},
    remarks: "",
    loading: false,
  });
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [quotations, setQuotations] = useState({
    subclass: [],
    products: [],
    insuranceClass: "",
    product_id: "",
    product_details: "",
    client_request_id: "",
    requirements: [],
    loading: false,
  });

  // Recommendation
  const [recommendation, setRecommendation] = useState({
    selectedProducts: [],
    recommendedProducts: [],
    binderOption: "",
    bindersApplied: [],
    product_id: "",
  });

  // asset states
  const [asset, setAsset] = useState([]);
  const [assetLimits, setAssetLimits] = useState([]);
  const [assetUploads, setAssetUploads] = useState([]);

  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: localStorage.getItem("lat"),
    lng: localStorage.getItem("lng"),
  });

  const [assetDetails, setAssetDetails] = useState({
    asset: [],
    reference: "",
    assetUploads: {
      file: null,
      imagePreviewUrl: "",
    },
    error: false,
  });

  // quotations
  const [listQuotations, setListQuotations] = useState({
    all_quotations: [],
    quotation_details: {},
    quotation_request: "",
    loading: false,
  });

  const [quotationRequests, setQuotationRequests] = useState({
    loading: false,
    data: [],
  });

  const [paginate, setPaginate] = useState({
    minValue: 0,
    maxValue: 10,
  });

  const numEachPage = 10;

  const [checked, setChecked] = useState({
    checkedList: [],
    limits: [],
    checkAll: false,
  });

  const [benefits, setBenefits] = useState([]);

  // wallet
  const [wallet, setWallet] = useState({
    data: [],
    loading: false,
    errors: "",
  });

  // appointments
  const [appointment, setAppointment] = useState({
    date: "",
    title: "",
    name: "",
    phone: "",
    description: "",
    location: "",
    underwriter: [],
    time: "",
    email: "",
    loading: false,
  });
  const [currentEvents, setCurrentEvents] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});

  // Policies state
  const [myPolicies, setMyPolicies] = useState({
    loading: false,
    data: [],
    error: "",
  });
  const [policyDetails, setPolicyDetails] = useState("");

  const [claimId, setClaimId] = useState({
    loading: false,
    id: "",
  });
  const [claimDetails, setClaimDetails] = useState({
    data: [],
    loading: false,
    error: "",
  });

  const [individualClaimDetails, setIndividualClaimDetails] = useState({
    loading: false,
    data: "",
    error: "",
  });

  const [claimTypes, setClaimTypes] = useState([]);

  const [claimFormFields, setClaimFormFields] = useState([]);

  const [makeClaimForm, setMakeClaimForm] = useState({
    data: [],
    error: "",
  });

  const [accountTrans, setAccountTrans] = useState("");

  const [commissionAccounts, setCommissionAccounts] = useState([]);

  // Notifications
  const [notifications, setNotifications] = useState({
    result: [],
    loading: false,
  });

  // UserManagement

  const [usmg, setUsmg] = useState({
    roles_list: [],

    loading: false,
    role_added: {
      name: "",
      description: "",
      role_code: "",
      loading: false,
    },
  });

  const [reportGroup, setReportGroup] = useState({
    name: "",
    code: "",
    description: "",
    loading: false,
  });

  const [usersmgt, setUsersmgt] = useState({
    name: "",
    email: "",
    username: "",
    ref: "",
    role: "",
    contact: "",
    department: "",
    security: "",
    loading: false,
  });

  const [resources, setResources] = useState([]);

  const [roleGrants, SetRoleGrants] = useState([]);

  const [roleDetails, setRoleDetails] = useState({
    data: [],
    laoding: false,
  });

  // UserManagement-Departments

  const [addDepartment, setAddDepartment] = useState({
    name: "",
    code: "",
    description: "",
    loading: false,
  });

  const [departments, setDepartments] = useState({
    data: "",
    loading: false,
  });

  const [departmentDetails, setDepartmentDetails] = useState({
    data: "",
    loading: false,
  });

  // UserManagement-SecurityContext

  const [securityContextList, setSecurityContextList] = useState({
    data: "",
    loading: false,
  });

  const [securityContext, setSecurityContext] = useState({
    name: "",
    password_policy: "",
    login_period_start: "",
    login_period_end: "",
    security_context_code: "",
    max_timeout: "",
    token_ttl: "",
    allowed_channels: "",
    browser_version: "",
    allowed_devices: "",
    device_version: "",
    two_factor_auth: "",
    holiday_login: "",
    description: "",
    loading: false,
  });

  // UserManagement - Users

  const [users, setUsers] = useState({
    data: [],
    loading: false,
  });

  // UserManagement - UserGroups

  const [userGroups, setUserGroups] = useState({
    data: [],
    loading: false,
  });

  const [addUserGroup, setAddUserGroup] = useState({
    name: "",
    code: "",
    description: "",
    loading: false,
  });

  const [userGroupDetails, setUserGroupDetails] = useState({
    data: [],
    loading: false,
  });

  // security Context

  const [securityDetails, setSecurityDetails] = useState({
    data: {},
    loading: false,
  });

  // Chat Messages
  const [chatMessages, setChatMessages] = useState([]);
  const [incomingMessage, setIncomingMessage] = useState({});
  const [myMessage, setMyMessage] = useState("");
  const [conversationId, setConversationId] = useState("");
  const [innerChat, setInnerChat] = useState(false);
  const [toChatId, setToChatId] = useState("");

  // Quotation Requests
  const [prodId, setProductId] = useState("");
  const [ref, setRef] = useState("");

  // Methods/ Functions

  const handlePaginationChange = (value) => {
    setPaginate({
      minValue: (value - 1) * numEachPage,
      maxValue: value * numEachPage,
    });
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  }, [alert, setShowAlert, showAlert]);

  async function logout() {
    await sessionStorage.removeItem("token");
    await history.push("/");
  }

  async function fetch_requests() {
    setLoading(true);
    try {
      let resp = await (await api_srv).get_requests();
      setData(resp.intermediary_requests);
      setPending(false);
      setLoading(false);

      // console.log("dashboard response", resp)
    } catch (err) {
      let error = await err;
      setLoading(false);
      console.log(error);
    }
  }

  async function fetch_dashboard_summary() {
    try {
      let resp = await (await api_srv).get_dashboard_summary();
      console.log("summary resp", resp.summary)
      setSummary(resp.summary);
    } catch (err) {
      let error = await err;
      console.log(error);
    }
  }

  async function set_intermediary_location() {
    setLoading(true);
    try {
      let resp = await (
        await api_srv
      ).set_location(coordinates.lat, coordinates.lng);
      modalClose();
      setLoading(false);
      // addToast("Location set successfully", {
      //   appearance: "success",
      //   autoDismiss: true,
      // });
    } catch (err) {
      let error = await err;
      modalClose();
      setLoading(false);

      // addToast(error.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  }

  async function fetch_underwriters_requests() {
    try {
      let resp = await (await api_srv).get_underwriters_requests();
      setUnderwriterRequests({
        ...underwriterRequests,
        underwriters: resp.underwritters,
      });
    } catch (err) {
      let error = await err;
      // addToast(error.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  }

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  // const handleInfiniteOnLoad = () => {
  //   setProfession({ loading: true });
  //   if (data.length > 14) {
  //     message.warning("List loaded completely");
  //     setProfession({ ...profession, setHasMore: false });
  //     setProfession({ ...profession, loading: false });

  //     return;
  //   }
  //   fetch_professions((res) => {
  //     //   data = data.concat(res.results);

  //     //   setLoading(data)
  //     setProfession({ ...profession, loading: false });
  //   });
  // };

  async function get_current_location() {
    try {
      navigator.geolocation.getCurrentPosition(function (position) {});
      navigator.geolocation.getCurrentPosition(function (position) {});
    } catch (err) {
      let error = await err;
      console.log(error);
    }
  }

  function handleClassChange(e) {
    setQuotations({ ...quotations, insuranceClass: e.target.value });
  }

  function handleProductChange(e) {
    setQuotations({ ...quotations, product_id: e.target.value });
  }

  function handleAssetChange(evt) {
    const value = evt.target.value;
    setAssetDetails({
      ...assetDetails,
      [evt.target.name]: value,
    });
  }

  function handleAssetUpload(evt) {
    evt.preventDefault();

    let reader = new FileReader();
    let uploadedFile = evt.target.files[0];
    let name = evt.target.name;
    reader.onloadend = () => {
      let upload = {
        [name]: uploadedFile,
      };

      setAssetUploads((assetUploads) => [...assetUploads, upload]);
    };

    reader.readAsDataURL(uploadedFile);
  }

  function addAsset(evt) {
    const value = evt.target.value;
    console.log("THE ASSET ON CONTEXT", asset);

    setAsset({
      ...asset,
      [evt.target.name]: value,
    });
  }

  const addMultiple = (name) => (value) => {
    console.log("started");
    console.log("ZINGS", name, value);
  };
  // setAsset({
  //   ...asset,
  //   [evt.target.name]: value
  // });

  const handleSelect = (evt) => {
    const value = evt.target.value;
    setAssetDetails({
      ...assetDetails,
      [evt.target.name]: value,
    });
  };

  function handleReportGroupChange(evt) {
    const value = evt.target.value;
    setReportGroup({
      ...reportGroup,
      [evt.target.name]: value,
    });
  }

  function handleClaimDetailsChange(evt) {
    const value = evt.target.value;

    setClaimDetails({
      ...claimDetails,
      [evt.target.name]: value,
    });
  }

  async function fetch_product_summary(product_id) {
    setQuotations({ ...quotations, loading: true });
    try {
      let resp = await (await api_srv).get_product_summary(product_id);
      setQuotations({ ...quotations, product_details: resp, loading: false });
      setLoading(false);
    } catch (err) {
      let error = await err;
      setQuotations({ ...quotations, loading: false });
      // addToast(error.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  }

  async function fetch_optional_benefits(product_id) {
    return new Promise(async function (resolve, reject) {
      setQuotations({ ...quotations, loading: true });
      try {
        let resp = await (await api_srv).get_product_summary(product_id);
        setQuotations({ ...quotations, product_details: resp, loading: false });
        return resolve(resp);
      } catch (err) {
        let error = await err;
        setQuotations({ ...quotations, loading: false });
        // addToast(error.message, {
        //   appearance: "error",
        //   autoDismiss: true,
        // });
        reject(err);
      }
    });
  }

  async function view_quotation_request(id) {
    try {
      let resp = await (await api_srv).view_quotation_request(id);
      setListQuotations({ ...listQuotations, quotation_request: resp });
    } catch (err) {
      let error = await err;
      console.log("error viewing quotations", error);
      // addToast(error,{
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  }

  async function create_quote() {
    if (assetDetails.reference === "") {
      setAssetDetails({ ...assetDetails, error: true });
    } else {
      setLoading(true);
      try {
        let resp = await (
          await api_srv
        ).create_quotation(
          quotations.client_request_id,
          quotations.product_id,
          assetDetails.reference
        );
        // await modalClose()
        // setCurrent(0)
        next();

        setLoading(false);
      } catch (err) {
        let error = await err;
        console.log("THE CREATE QUOTE ERROR", error);
        setLoading(false);
        setShowAlert(true);
      }
    }
  }

  async function fetch_asset_requirement(id) {
    setQuotations({ ...quotations, loading: true });
    try {
      let resp = await (await api_srv).get_asset_requirements(id);

      setQuotations({
        ...quotations,
        requirements:
          resp.general_requirement.subclass_general_requirement.concat(
            resp.product
          ),
        loading: false,
      });
    } catch (err) {
      let error = await err;
      setQuotations({ ...quotations, loading: false });
      console.log("error creating quotations", error);
    }
  }

  async function send_to_client(risk, benefits, client_quotation_id) {
    setLoading(true);
    try {
      const assets = risk.map((item) => item);
      const benefit = benefits.map((item) => item);

      let resp = await (
        await api_srv
      ).send_quotation(assets, benefit, client_quotation_id);

      setLoading(false);
      setModalShow(true);
      // next()
    } catch (err) {
      let error = await err;
      console.log(error.message);
      setLoading(false);
    }
  }

  async function add_report_group() {
    setReportGroup({ ...reportGroup, loading: true });
    try {
      setReportGroup({ ...reportGroup, loading: false });
    } catch (err) {
      let error = await err;
      setReportGroup({ ...reportGroup, loading: false });
    }
  }

  async function fetch_commission() {
    try {
      let resp = await (await api_srv).fetch_commission_account();

      setCommissionAccounts(resp.entity_accounts);
    } catch (err) {
      let error = await err;
    }
  }

  async function get_notifications() {
    setNotifications({ ...notifications, loading: true });
    try {
      let resp = await (await api_srv).fetch_notifications();
      setNotifications({ ...notifications, result: resp, loading: false });
    } catch (err) {
      let error = await err;
      setNotifications({ ...notifications, loading: false });
    }
  }
  async function fetch_subclass_product() {
    setQuotations({ ...quotations, loading: true });
    try {
      let resp = await (
        await api_srv
      ).get_subclass_products(quotations.insuranceClass);

      setQuotations({ ...quotations, products: resp, loading: false });
    } catch (err) {
      let error = await err;
      setQuotations({ ...quotations, loading: false });
      // addToast(error.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  }

  return (
    <DashboardContext.Provider
      value={{
        isToggled,
        setToggled,
        address,
        setAddress,
        coordinates,
        setCoordinates,
        collapsed,
        setCollapsed,
        toggleSidebar,
        get_current_location,
        fetch_requests,
        info,
        setInfo,
        quotationRequests,
        setQuotationRequests,
        data,
        setData,
        redirState,
        // handleInfiniteOnLoad,
        handleAssetChange,
        fetch_underwriters_requests,
        pending,
        setPending,
        setState,
        id,
        setId,
        summary,
        setSummary,
        fetch_dashboard_summary,
        truncateString,
        profession,
        setProfession,
        handleClassChange,
        assetDetails,
        handleAssetUpload,
        handleSelect,
        underwriterRequests,
        setUnderwriterRequests,
        professionList,
        setProfessionList,
        logout,
        handleProductChange,
        set_intermediary_location,
        loading,
        quotations,
        setQuotations,
        fetch_product_summary,
        fetch_optional_benefits,
        search,
        setSearch,
        fetch_asset_requirement,
        handlePaginationChange,
        paginate,
        setPaginate,
        numEachPage,
        listQuotations,
        setListQuotations,
        create_quote,
        view_quotation_request,
        addAsset,
        asset,
        setAsset,
        benefits,
        setBenefits,
        checked,
        setChecked,
        send_to_client,
        wallet,
        setWallet,
        myUnderwriters,
        setMyUnderwriters,
        assetLimits,
        setAssetLimits,
        assetUploads,
        setAssetUploads,
        currentEvents,
        setCurrentEvents,
        appointment,
        setAppointment,
        handleReportGroupChange,
        reportGroup,
        add_report_group,
        usersmgt,
        setUsersmgt,
        securityContext,
        setSecurityContext,
        myPolicies,
        setMyPolicies,
        accountTrans,
        setAccountTrans,
        claimTypes,
        claimFormFields,
        setClaimFormFields,
        fetch_commission,
        commissionAccounts,
        policyDetails,
        claimId,
        setClaimId,
        handleClaimDetailsChange,
        claimDetails,
        setClaimDetails,
        individualClaimDetails,
        setIndividualClaimDetails,
        makeClaimForm,
        setMakeClaimForm,
        notifications,
        get_notifications,
        selectedObject,
        setSelectedObject,
        usmg,
        setUsmg,
        resources,
        setResources,
        roleGrants,
        SetRoleGrants,
        roleDetails,
        setRoleDetails,
        addDepartment,
        setAddDepartment,
        departments,
        setDepartments,
        departmentDetails,
        setDepartmentDetails,
        securityContextList,
        setSecurityContextList,
        users,
        setUsers,
        userGroups,
        setUserGroups,
        addUserGroup,
        setAddUserGroup,
        userGroupDetails,
        setUserGroupDetails,
        securityDetails,
        setSecurityDetails,
        recommendation,
        setRecommendation,
        fetch_subclass_product,
        addMultiple,
        chatMessages,
        setChatMessages,
        incomingMessage,
        setIncomingMessage,
        myMessage,
        setMyMessage,
        conversationId,
        setConversationId,
        innerChat,
        setInnerChat,
        toChatId,
        setToChatId,
        prodId,
        setProductId,
        ref,
        setRef,
        formState,
        setFormState,
        refreshAppointments,
        setRefreshAppointments
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

const DashboardConsumer = DashboardContext.Consumer;
export { DashboardContextProvider, DashboardConsumer, DashboardContext };
