import React, { useContext } from 'react';

import { useQuery } from 'react-query';

import { DashboardContext } from 'context/DashboardContext';
import { fetch_risk_form } from 'services/apiSrv';
import Loader from 'components/widgets/loader/ComponentLoader';
import RiskForm from 'components/widgets/forms/DynamicAssetForm';

const ProductRequirement = ({ systemRef, clientId, refetchRisks }) => {
	const { recommendation } = useContext(DashboardContext);
	const getForm = async () => {
		let resp = await fetch_risk_form(recommendation.insuranceClass);
		return resp.quotation_form;
	};

	const { isLoading, isSuccess, data } = useQuery('riskForm', getForm);
	console.log("data risk form ", data)

	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && (
				<RiskForm
					fields={data}
					title="Fill the Product Requirement"
					id="RISK_FORM"
					systemRef={systemRef}
					clientId={clientId}
					refetchRisks={refetchRisks}
				/>
			)}
		</div>
	);
};

export default ProductRequirement;
