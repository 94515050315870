import React, { memo, useEffect, useState  } from 'react';
import { Modal } from 'react-bootstrap';
import '../../../assets/css/dashboard.css';
import * as Icon from 'react-feather';
import { toast } from 'react-toastify';
import Loader from 'components/widgets/loader/ComponentLoader';
import Moment from 'react-moment';
import { manage_appointment } from "services/apiSrv";

const ViewAppointment = (props) => {
	const { data, isLoading, isError, isSuccess, onHide } = props;
	const appointmentDecision = data?.appointment_decision;
	const appointmentFrom = data?.from;	
	const showActionButtons = appointmentDecision === "PENDING" && appointmentFrom === "CLIENT";

	console.log("ViewAppointment Data:", data);
    console.log("Appointment ID:", data?.intermediary_appointment_id);

	const [isProcessing, setIsProcessing] = useState(false);

	//const [isAccepting, setIsAccepting] = useState(false);
	//const [isRejecting, setIsRejecting] = useState(false);

	// Common API call function for accept & reject
	const handleAppointmentAction = async (actionType) => {
		setIsProcessing(true);
		const requestBody = {
		  resource: "intermediaryAppointment",
		  action: actionType === "accept" ? "approve_appointment" : "reject_appointment",
		  data: {
			intermediary_appointment_id: data.appointment_id,
		  },
		};
	
		try {
		  await manage_appointment(actionType, data.intermediary_appointment_id);
		  toast.success(`Appointment ${actionType}ed successfully!`);
		  onHide(); // Close modal on success
		} catch (error) {
		  toast.error(`Failed to ${actionType} appointment.`);
		} finally {
		  setIsProcessing(false);
		}
	  };

	console.log("appointment", data) 
	return (
		<div>
			<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static">
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className="text-grey">Appointment Details</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{isLoading && <Loader />}
					{isError && <p>OOPS! Something went wrong!</p>}
					{isSuccess && data && (
						<>
							<div className="row">
								<div className="col-md-12 mb-3">
									<div className="row">
										<div className="col-md-1">
											<Icon.User size={20} />
										</div>
										<div className="col-md-11">{data.client_details.client_name}</div>
									</div>
								</div>
								<div className="col-md-12 mb-3">
									<div className="row">
										<div className="col-md-1">
											<Icon.Phone size={20} />
										</div>
										<div className="col-md-11">{data.client_details.client_contact}</div>
									</div>
								</div>
								<div className="col-md-12 mb-3">
									<div className="row">
										<div className="col-1">
											<Icon.Mail size={20} />
										</div>
										<div className="col-11">{data.client_details.client_email}</div>
									</div>
								</div>
								<div className="col-md-12 mb-3">
									<div className="row">
										<div className="col-1">
											<Icon.Briefcase size={20} />
										</div>
										<div className="col-11">Underwriter</div>
									</div>
								</div>
								<div className="col-md-12 mb-3">
									<div className="row">
										<div className="col-md-1">
											<Icon.Clock size={20} />
										</div>

										<div className="col-md-11">
											<Moment format="DD-MMM-YYYY">{data.appointment_date}</Moment>
											<span className="d-block">
												~ {''}
												{''}
												<Moment format="HH:MM:a">{data.appointment_date}</Moment>
											</span>
										</div>
									</div>
								</div>

								<div className="col-md-12 mb-3">
									<div className="row">
										<div className="col-md-1">
											<Icon.Map size={20} />
										</div>
										<div className="col-md-11">Appointment Location</div>
									</div>
								</div>
								<div className="col-md-12 mb-3">
									<div className="row">
										<div className="col-md-1">
											<Icon.FileText size={20} />
										</div>
										<div className="col-md-11">{data.appointment_description}</div>
									</div>
								</div>
							</div>
							{/* Accept & Reject Buttons */}
							{ data?.from === "CLIENT" && (
								<div className="d-flex justify-content-between mt-4">
									<button
									onClick={() => handleAppointmentAction("reject")}
									disabled={isProcessing}
									className="btn btn-outline-danger px-4"
									>
									{isProcessing ? "Processing..." : "Reject"}
									</button>

									<button
									onClick={() => handleAppointmentAction("accept")}
									disabled={isProcessing}
									className="btn btn-outline-success px-4"
									>
									{isProcessing ? "Processing..." : "Accept"}
									</button>
								</div>
							)}
						</>
					)}
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default memo(ViewAppointment);
